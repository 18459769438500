import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent  implements OnInit {


  @Input() displayText: string = ``;
  @Input() isBold?: boolean = false;
  @Input() color?: string =  `#666666`; 
  @Input() fontSize: string = ``;

  constructor() { }

  ngOnInit() {
    console.debug(`[DescriptionComponent][ngOnInit][To be Implemented later]`);
  }

}
