<div class="contact-list-title">
  <app-h2-heading [displayText]="title"></app-h2-heading>
</div>

<ion-list class="contact-list">
  <ng-container *ngIf="filteredContacts.length > 0; else noMatchTemplate">
    <ion-item *ngFor="let contact of filteredContacts" class="contact-item" (click)="onContactSelect(contact)">
      <app-user-profile-card 
        [heading]="contact.firstName + ' ' + contact.lastName" 
        [description]="contact.mobileNumber" 
        [profileImage]="contact.profilePic ?? ''"> 
      </app-user-profile-card>
    </ion-item>
  </ng-container>
</ion-list>

<!-- Template for No Matches Found -->
<ng-template #noMatchTemplate>
  <div class="no-match-container">
    <!-- Error Message Component -->
    <app-message 
      [messageType]="'alert'" 
      [message]="'Cannot find an exact match for your search. Try another number.'"              
      >
    </app-message>

    <!-- Invite Now Card -->
    <div class="invite-now-card">
      <img src="assets/images/invite-user.png" alt="Invite Friends" class="invite-image">    
    </div>
  </div>
</ng-template>
