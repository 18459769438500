import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* TODO: Remove the inline initialization and import from the model */
// Inline definition for PaymentMethodItem
enum PaymentMethod {
  MobileNumber = `Mobile Number`,
  WalloId = `Wallo ID`,
  QRCode = `QR Code`,
}

type PaymentMethodItem = { paymentMethod: PaymentMethod, icon: string };

@Component({
  selector: `app-biometrics-modal`,
  templateUrl: `./biometrics-modal.component.html`,
  styleUrls: [`./biometrics-modal.component.scss`],
})
export class BiometricsModalComponent implements OnInit, OnChanges {
  @Input() headingText: string = ``;
  @Input() showDescription: boolean = false;
  @Input() descriptionText: string = ``;
  @Input() showImage: boolean = false;
  @Input() imageSrc: string = ``;
  @Input() showLink: boolean = false;
  @Input() linkText: string = ``;
  @Input() showButtons: boolean = false;
  @Input() showFirstButton: boolean = false;
  @Input() showSecondButton: boolean = false;
  @Input() firstButtonText: string = ``;
  @Input() secondButtonText: string = ``;
  @Input() verifyButtonText: string = ``;
  @Input() firstButtonColor: string = ``;
  @Input() secondButtonColor: string = ``;
  @Input() showPaymentMethods: boolean = false;
  @Input() paymentMethodItems: PaymentMethodItem[] = [];
  @Input() showInputField: boolean = false;
  @Input() showVerifyButton: boolean = false;
  @Input() walloIdPattern: RegExp | undefined = undefined;
  @Input() errorMessage: string = ``;

  @Output() secondButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() firstButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() biometricsAuthentication: EventEmitter<void> = new EventEmitter<void>();
  @Output() pinInputPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() methodSelect: EventEmitter<PaymentMethodItem> = new EventEmitter<PaymentMethodItem>();
  @Output() submitWalloId: EventEmitter<string> = new EventEmitter<string>();

  walloIdForm: FormGroup;
  headingFontSize: string = `1.125rem`;
  headingColor: string = `#000000`;
  paymentMethodColor: string = `#000000`;

  get isOnlySecondButtonVisible(): boolean {
    return this.showSecondButton && !this.showFirstButton;
  }

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.walloIdForm = this.fb.group({
      walloId: ['', [Validators.required]] // Initial setup, will update in ngOnInit
    });
  }

  ngOnInit(): void {
    
    if (this.walloIdPattern) {
      this.walloIdForm.get('walloId')?.setValidators([
        Validators.required,
        Validators.pattern(this.walloIdPattern)
      ]);
    }

    this.walloIdForm.get('walloId')?.valueChanges.subscribe(() => {
      this.resetErrorMessage();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorMessage']) {
      console.log('Error message changed:', changes['errorMessage'].currentValue);
      // Temporarily clear the error message to force change detection
      if (changes['errorMessage'].currentValue !== '') {
        this.errorMessage = '';
        setTimeout(() => {
          this.errorMessage = changes['errorMessage'].currentValue;
          this.cdRef.detectChanges(); // Ensure UI updates with new error message
        }, 0);
      }
    }
  }

  /**
   * @description Emit the firstButton function
   */
  onFirstButtonClick(): void {
    this.firstButtonClick.emit();
  }

  /**
   * @description Emit the closeModal function
   */
  onSecondButtonClick(): void {
    this.secondButtonClick.emit();
  }

  /**
   * @description Emit the biometricsAuthentication function
   */
  onBiometricsAuthentication(): void {
    this.biometricsAuthentication.emit();
  }

  /**
   * @description Emit the navigateToPinInputPage function
   */
  navigateToPinInputPage(): void {
    this.pinInputPage.emit();
  }

  onMethodSelect(method: PaymentMethodItem): void {
    this.methodSelect.emit(method);
  }

  /**
   * @description Handle Wallo ID form submission
   */
  onSubmit(): void {
    if (this.walloIdForm.valid) {
      const walloId = this.walloIdForm.get('walloId')?.value;
      this.submitWalloId.emit(walloId); // Emit the Wallo ID to the parent component for verification
    } else {
      this.walloIdForm.markAllAsTouched();     
    }
  }

  /**
   * @description Reset error messages
   */
  resetErrorMessage(): void {
    this.errorMessage = ``;
    this.cdRef.detectChanges(); // Ensure UI updates when error is reset
  }  
}
