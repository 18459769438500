import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { PinInputComponent } from '../pin-input/pin-input.component';
import { every, isInteger, join } from 'lodash';

@Component({
  selector: 'app-pin-component',
  templateUrl: './pin-component.component.html',
  styleUrls: ['./pin-component.component.scss'],
})
export class PinComponentComponent  implements OnInit, OnDestroy {
  @Input() firstHeadingText: string = ``;
  @Input() subHeadingText: string = ``;
  @Input() descriptionText: string = ``;
  @Input() errorMessage: string  = ``;
  @Input() showResendCode: boolean = false; 
  @Input() errorCondition: boolean = false;
  @Input() secondHeadingText: string = ``;
  @Input() forgotPinText: string = ``;
  @Input() showFirstHeading: boolean = false;
  @Input() showSubHeading: boolean = false;
  @Input() showDescription: boolean = false;
  @Input() showSecondHeading: boolean = false; 
  @Input() showForgotPin: boolean = false;
  @Input() showNavigationLink: boolean = false;
  @Input() navigationLinkText: string = ``;
  @Input() showHeader: boolean = false;
  @Input() maskPin: boolean = true;

  @ViewChild(PinInputComponent) pinInputComponent!: PinInputComponent;

  userPin: Array<string> = new Array<string>(4); 
  timer: number = 60; 
  timerInterval: any;



  @Output() updateUserPin = new EventEmitter<Array<string>>(); 
  @Output() resendCodeClicked = new EventEmitter<void>();
  @Output() navigationLinkClicked = new EventEmitter<void>();
  constructor() { }
  /**
   * 
   * @param event 
   * @description This function is used to emit the userPin
   */
  onUserPinChange(event: any) {
    this.updateUserPin.emit(event);   
  }
  /**
   * @description This function is used to resend the code
   */
  resendCode(): void
  {
    this.pinInputComponent.clearInputs();
    this.errorCondition = false;
    this.startTimer();
    this.resendCodeClicked.emit();
  }
/**
 * @description  This function is used to start the timer 
 */
  startTimer(): void {
    this.timer = 60;
    this.timerInterval = setInterval((): void => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }
 
  /**
   * @description This function is called to clear the timer
   */
   clearTimer(): void {
    this.errorCondition = true;
    clearInterval(this.timerInterval);
    this.timer = 0;
  }

  ngOnDestroy(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
  /**
   * 
   * @returns boolean
   * @description  This function is used to check if the code entered by the user is valid
   */
  isCodeValid(): boolean {
    return every(this.userPin, (char: string) => isInteger(Number(char)) && char.length === 1) && join(this.userPin, '').length === 4;
  }
  
  ngOnInit() {
    console.log(/** [PinComponentComponent:ngOnInit] */);
    this.userPin = [``,``,``,``];   
    this.startTimer();  
  }
/**
 * @description This function is used to clear the pin inputs
 */
  clearPinInputs(): void {
    this.pinInputComponent.clearInputs();
  }  

  // Emit an event when the navigation link is clicked
  onNavigate(): void {
    this.navigationLinkClicked.emit();   
  }

    clearPin(): void {
    this.userPin = [``, ``, ``, ``];
    this.updateUserPin.emit(this.userPin);
  }
}