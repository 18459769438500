import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent  implements OnInit {
  @Input() iconName: string = ``;        // Name of the ion-icon 
  @Input() bgColor: string = ``;
  @Input() text: string = ``;
  @Input() isActive: boolean = false;   // Flag to determine active state

  @Output() iconClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    console.log(`Icon Component`);    
  }

  get textColor(): string {
    return this.isActive ? `#2B6EFE` : `#666666`; // Active: Blue, Inactive: Grey
  }

   handleClick(): void {
    this.iconClick.emit(this.text);  // Emit click event with icon text
  }

}
