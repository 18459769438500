<swiper-container class="mySwiper" effect="cards" loop="true">
  <swiper-slide *ngFor="let bank of bankList">
    <div class="card-content">
      <div class="card-background">
        <img [src]="cardBackgroundImagePath" alt="User Card Background" class="card-image" />
      </div>
      <div class="card-details">
        
        <!-- Align Primary label and Total Balance text on the same line -->
        <div class="card-header">        
          <app-description 
            [displayText]="totalBalanceLabel" 
            [color]="textColor" 
            class="total-balance-label" 
            [fontSize]="totalBalanceFontSize">
          </app-description>
          
          <!-- Show/Hide Primary label using CSS visibility -->
          <app-description 
            [displayText]="primaryLabel" 
            [color]="textColor" 
            [fontSize]="primaryFontSize" 
            [style.visibility]="bank.isPrimary ? 'visible' : 'hidden'" 
            class="primary-label">
          </app-description>
        </div>
        
        <!-- Balance amount displayed below the header -->
        <app-h2-heading 
          [displayText]="bank.balance || '0'" 
          class="total-balance" 
          [color]="textColor" 
          [isBold]="true" 
          [fontSize]="balanceFontSize">
        </app-h2-heading>
        
        <div class="bank-details">
          <!-- Nickname, Bankname and account number -->
          <app-description 
            [displayText]="bank.bankName" 
            [color]="textColor" 
            [fontSize]="bankNameFontSize" 
            [isBold]="true">
          </app-description>
          <app-description 
            [displayText]="bank.nickName" 
            [color]="textColor" 
            [fontSize]="nickNameFontSize">
          </app-description>      
          <app-description 
            [displayText]="formatAccountNumber(bank.accountNumber)" 
            [color]="textColor" 
            [fontSize]="accountNumberFontSize" 
            class="account-number" 
            [isBold]="true">
          </app-description>
        </div>       
      </div>
    </div>
  </swiper-slide>
</swiper-container>
