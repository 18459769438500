import { Component, Input, OnInit } from '@angular/core';
 /* TODO: Remove the inline initialization and import from the model */
type Transaction = {
  title: string;
  profileImage: string;
  date: string;
  time: string;
  amount: number;
  transactionType?: 'credit' | 'debit'; 
  firstName?: string;
  lastName?: string;
};

@Component({
  selector: 'app-recent-transactions',
  templateUrl: './recent-transactions.component.html',
  styleUrls: ['./recent-transactions.component.scss'],
})
export class RecentTransactionsComponent  implements OnInit {
   @Input() transactions: Transaction[] = [];

    // Styling configurations
  descriptionColor: string = `#666666`;
  descriptionFontSize: string = `0.75rem`;
  headingFontSize: string = `0.938rem`;
  dateAndTimeSpacing: string = `\u00A0\u00A0\u00A0`;

  constructor() { }

  ngOnInit() {
    console.log(`Recent Transactions`);
  }

}
