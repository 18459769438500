<div class="code-inputs">
  <ion-input inputmode="numeric" #input maxlength="1" [(ngModel)]="userPin[0]" 
    (ngModelChange)="getValue(1, $event)" 
    (keydown)="handleBackspace($event, 1)" 
    [type]="isMasked ? 'password' : 'text'">
  </ion-input>

  <ion-input inputmode="numeric" #input maxlength="1" [(ngModel)]="userPin[1]" 
    (ngModelChange)="getValue(2, $event)" 
    (keydown)="handleBackspace($event, 2)" 
    [type]="isMasked ? 'password' : 'text'">
  </ion-input>

  <ion-input inputmode="numeric" #input maxlength="1" [(ngModel)]="userPin[2]" 
    (ngModelChange)="getValue(3, $event)" 
    (keydown)="handleBackspace($event, 3)" 
    [type]="isMasked ? 'password' : 'text'">
  </ion-input>

  <ion-input inputmode="numeric" #input maxlength="1" [(ngModel)]="userPin[3]" 
    (ngModelChange)="getValue(4, $event)" 
    (keydown)="handleBackspace($event, 4)" 
    [type]="isMasked ? 'password' : 'text'">
  </ion-input>
</div>
