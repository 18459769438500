<ion-list class="bank-list">
  <ion-item *ngFor="let bank of bankList" class="bank-card">
    <ion-thumbnail slot="start">
      <img [src]="bank.bankLogo" alt="Bank Logo" class="bank-logo">
    </ion-thumbnail>
    <ion-label class="bank-info">
      <app-description [displayText]="bank.nickName" [isBold]="nickNameIsBold" [color]="nickNameColor" class="bank-name"></app-description>
      <app-description [displayText]="bank.accountNumber" [color]="accountColor" [fontSize]="accountNumberFontSize"></app-description>
      <app-description [displayText]="'Primary'" [color]="primaryColor" [fontSize]="primaryFontSize" class="primary-label" 
                       [ngStyle]="{'visibility': bank.isPrimary ? 'visible' : 'hidden'}"></app-description>
    </ion-label>
    <ion-button fill="clear" class="right-icon" [iconOnly]="true" (click)="presentPopover($event, bank)">
      <ion-icon name="ellipsis-vertical-outline"></ion-icon>
    </ion-button>
  </ion-item>
</ion-list>
