import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
})
export class LocaleSelectorComponent implements OnInit {
  /* TODO: Remove the inline initialization and import from the model */
  @Input() languages: { code: string; label: string; flag?: string }[] = [];
  @Output() languageChange = new EventEmitter<string>();   // Emit language change to parent
  selectedLanguage: { code: string; label: string; flag?: string } | undefined = undefined; // Use undefined initially
  dropdownOpen: boolean = false;

  ngOnInit(): void {
    // Ensure that selectedLanguage is set after the input languages are received
    if (this.languages && this.languages.length > 0) {
      this.selectedLanguage = this.languages[0];  // Default to the first language
    }
  }

  /**
   * Toggles the dropdown menu for language selection.
   */
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  /**
   * Changes the selected language when a language option is clicked.
   * Emits the language option to the parent component.
   * 
   * @param {string} language - The language option selected by the user.
   */
  onLanguageChange(language: { code: string; label: string; flag?: string }): void  {
    console.log(`LocaleSelectorComponent: Changing language to ${language.label}`);
    this.selectedLanguage = language;
    this.languageChange.emit(language.code);  // Emit the selected language to the parent component
    this.dropdownOpen = false;
  }
}
