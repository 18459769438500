<ion-list>
  <!-- Disable "Set as Primary" if the account is already primary -->
  <ion-item button [disabled]="bank.isPrimary" (click)="onSetAsPrimary()" [detail]="false">
    Set as Primary
  </ion-item>
  
  <!-- "Edit" option remains enabled -->
  <ion-item button (click)="onEdit()" [detail]="false">
    Edit
  </ion-item>
  
  <!-- Disable "Delete" if there is only one bank account or if the account is primary -->
  <ion-item button [disabled]="isDeleteDisabled()" (click)="onDelete()" [detail]="false">
    Delete
  </ion-item>
</ion-list>
