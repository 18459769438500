import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-h4-heading',
  templateUrl: './h4-heading.component.html',
  styleUrls: ['./h4-heading.component.scss'],
})
export class H4HeadingComponent  implements OnInit {
  
  @Input() displayText: string = ``;
  @Input() isBold?: boolean;
  @Input() color?: string = `#000000`;
  
  constructor() { }

  ngOnInit() 
  {
    console.debug(`[H4HeadingComponent][ngOnInit][To be Implemented later]`);
  }

}
