import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pay-icon',
  templateUrl: './pay-icon.component.html',
  styleUrls: ['./pay-icon.component.scss'],
})
export class PayIconComponent  implements OnInit {
  @Output() payButtonClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
    console.log(`Pay Icon Component`);
  }

  /**
   * @description This method is used to emit the click event
   */

  onPayButtonClick() :void {
    this.payButtonClick.emit();
  }
}
