<div class="profile-qr-wrapper">
  <!-- Left: QR Code Section -->
  <div class="profile-qr-code">
    <div class="qr-section">
      <!-- QR Code Image -->
      <img [src]="qrCodeImagePath" alt="QR Code" class="qr-image">
    </div>
    <!-- Bank Name -->
    <div class="bank-name">
      <app-description [displayText]="bankName"></app-description>
    </div>
  </div>

  <!-- Right: QR Actions Section (Share and Download Buttons) -->
  <div class="qr-actions">
    <!-- Share QR Button -->
    <div class="qr-action-button" (click)="onShareQR()">
      <ion-icon name="share-social-outline"></ion-icon>
      <app-description 
        [displayText]="shareButtonText" 
        [fontSize]="buttonFontSize" 
        [color]="buttonColor">
      </app-description>     
    </div>

    <!-- Download QR Button -->
    <div class="qr-action-button" (click)="onDownloadQR()">
      <ion-icon name="download-outline"></ion-icon>
      <app-description 
        [displayText]="downloadButtonText" 
        [fontSize]="buttonFontSize"  
        [color]="buttonColor">
      </app-description>     
    </div>
  </div>
</div>
