<ion-list class="notification-list">
  <ion-item *ngFor="let notification of notifications" class="notification-item">
    
    <!-- Common profile card for both transaction and non-transaction notifications -->
    <app-user-profile-card 
      [heading]="notification.transaction?.title ?? notification.title ?? defaultTitle" 
      [description]="notification.message || ((notification.transaction?.date || defaultDate) + spacing + (notification.transaction?.time || defaultTime) || notification.date || defaultDate)" 
      [profileImage]="notification.transaction?.profileImage ?? notification.profileImage ?? defaultProfileImage"
      [descriptionColor]="descriptionColor"
      [descriptionFontSize]="descriptionFontSize"
      [headingFontSize]="headingFontSize"
      [firstName]="notification.transaction?.firstName || defaultFirstName"
      [lastName]="notification.transaction?.lastName || defaultLastName">
    </app-user-profile-card>

    <!-- Display transaction amount only for transaction notifications -->
    <ng-container *ngIf="notification.type === 'transaction'">
      <app-transaction-amount 
        [amount]="notification.transaction?.amount ?? defaultAmount" 
        [transactionType]="notification.transaction?.transactionType ?? defaultTransactionType">
      </app-transaction-amount>
    </ng-container>

  </ion-item>
</ion-list>
