import { Component, Input, OnInit, forwardRef, ViewChild, ElementRef,  AfterViewInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputConfig } from '../../models';
import { InputMode } from 'src/app/models/input-mode.enum';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() inputConfig: InputConfig = {
    label: '',
    placeHolder: '',
    inputType: 'text',
    formControlName: null,
    shape: 'round',
    fill: 'outline',
    rows: 1,    
  };
 
  @Input() disabled: boolean = false;
  @Input() inputMode: InputMode = InputMode.TEXT;
  @Input() value: string | number = ``;  
  @Input() padding: boolean = true;
  @Input() width: string | null = null; // If null, use default CSS width (100%)

  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;

 
  onChange?: Function;
  onTouched?: Function;

  constructor() {}

  ngOnInit() {
    console.log(`[InputComponent:ngOnInit]`, this.inputConfig);
  }

  ngAfterViewInit() {
   console.log(`[InputComponent:ngAfterViewInit]`, this.inputConfig);
  }

  writeValue(value: string | number): void {
    this.value = value;
  }

  registerOnChange(callback: Function): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: Function): void {
    this.onTouched = callback;
  }

  handleInput(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.value = target.value;
   // this.onChange!(this.value);
   // this.onTouched!();
    if (this.onChange) {
      this.onChange(this.value); 
    }
    if (this.onTouched) {
      this.onTouched(); 
    }
  }

  setFocus() {
    this.inputElement?.nativeElement.focus();
  }
}
