import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-h2-heading',
  templateUrl: './h2-heading.component.html',
  styleUrls: ['./h2-heading.component.scss'],
})
export class H2HeadingComponent  implements OnInit {

  @Input() displayText: string = ``;
  @Input() isBold?: boolean;
  @Input() color?: string = `black`;
  @Input() fontSize: string = ``;
  constructor() { }

  ngOnInit() {
    console.debug(`[H2HeadingComponent][ngOnInit][To be Implemented later]`);
  }

}
