import { Component, Input } from '@angular/core';
import Swiper from 'swiper';
 /* TODO: Remove the inline initialization and import from the model */
type BankAccount = {
  isPrimary: boolean;
  bankName: string;
  accountNumber: string;
  nickName: string;
  bankLogo?: string;
  balance?: string;
};

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() bankList: BankAccount[] = [];  // Receive bankList from parent component
  swiper: Swiper | null = null;  // Swiper instance

  formatAccountNumber(accountNumber: string): string {
    const visibleDigits: string = accountNumber.slice(-4);
    const hiddenDigits: string = accountNumber.slice(0, -4).replace(/\d/g, `*`);
    return `${hiddenDigits}${visibleDigits}`.replace(/(.{2})(.{4})(.{8})(.{2,3})/, `$1 $2 $3 $4`).trim();
  }
}
