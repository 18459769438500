import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { ManageBankPopoverComponent } from 'src/app/component/manage-bank-popover/manage-bank-popover.component';

/* TODO: Remove this initialization and import from the model */
type BankAccount = {
  isPrimary: boolean;
  bankName: string;
  accountNumber: string;
  nickName: string;
  bankLogo?: string;
  balance?: string;
};


@Component({
  selector: `app-bank-list-view`,
  templateUrl: `./bank-list-view.component.html`,
  styleUrls: [`./bank-list-view.component.scss`],
})
export class BankListViewComponent implements OnInit {
  @Output() deleteBankAccount: EventEmitter<BankAccount> = new EventEmitter<BankAccount>(); // Re-emit the delete event to the parent
  @Output() setPrimaryBankAccount: EventEmitter<BankAccount> = new EventEmitter<BankAccount>(); // Re-emit set as primary event
  @Input() bankList: BankAccount[] = [];
  @Input() navigationLink: string = ``;
 
  constructor(private popoverController: PopoverController, private navigationController: NavController) {}

  ngOnInit(): void {
    console.log(`Bank List View Component`);    
  }

  
  formatAccountNumber(accountNumber: string): string {    
    const regexPattern: RegExp = /^(\d{2})(\d{4})(\d{8})(\d{2,3})$/;
    return accountNumber.replace(regexPattern, `$1 $2 $3 $4`);
  }
  

  async presentPopover(event: Event, bank: BankAccount): Promise<void> {
    const popover = await this.popoverController.create({
      component: ManageBankPopoverComponent,
      event, 
      translucent: true, 
      backdropDismiss: true, 
      componentProps: {
        bank,
        totalBankAccounts: this.bankList.length,        
      },
      cssClass: 'custom-popover-class' // Custom CSS class for styling
    });
  
    popover.onDidDismiss().then((result) => {
      if (result.data && result.data.action === `delete`) {
        this.onDeleteBankAccount(result.data.bank); // Handle the delete action
      } else if (result.data && result.data.action === `setAsPrimary`) {
        this.onSetAsPrimary(result.data.bank); // Handle set as primary action
      } else if (result.data && result.data.action === `edit`) {
        this.onEditBankAccount(result.data.bank); // Handle the edit action
      }
    });
  
    await popover.present();
  }
  

  /**
   * Re-emits the delete event to the parent component (BankPage).
   * @param {BankAccount} bank - The bank account to delete.
   */
  onDeleteBankAccount(bank: BankAccount): void {
    console.log(`onDeleteBankAccount in bank list view component`, bank);
    this.deleteBankAccount.emit(bank); // Re-emit the event to the parent component (BankPage)
  }

   /**
   * Handle setting the bank account as primary
   * @param {BankAccount} bank - The bank account to set as primary
   */
   onSetAsPrimary(bank: BankAccount): void {
    this.setPrimaryBankAccount.emit(bank);  // Re-emit the set as primary event
  }

   /**
   * Handle the edit action from ManageBankPopoverComponent
   * @param {BankAccount} bank - The bank account to edit
   */
   onEditBankAccount(bank: BankAccount): void {
    this.navigationController.navigateForward(this.navigationLink, {
      state: { editMode: true, bank }
    });
  }
}
