<div class="user-profile">
  <!-- Profile Image or Initials -->
  <div 
    class="profile-container" 
    [ngStyle]="!profileImage ? {'background-color': 'var(--primary-bg-color)'} : {}"
  >
    <ng-container *ngIf="profileImage; else initialsDiv">
      <img [src]="profileImage" class="profile-image" alt="Profile" />
    </ng-container>
    <!-- Display Initials if no profile image -->
    <ng-template #initialsDiv>
      <div class="profile-initials">{{ initials }}</div>
    </ng-template>
  </div>

  <!-- User Details Section -->
  <div class="user-details">
    <app-description [displayText]="heading" [fontSize]="headingFontSize" [color]="headingColor" [isBold]="headingBold"></app-description>
    <app-description 
      [displayText]="description" 
      [color]="descriptionColor" 
      [isBold]="descriptionIsBold" 
      [fontSize]="descriptionFontSize">
    </app-description>
  </div>
</div>
