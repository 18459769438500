export const environment = {
  production: false,
  apiBaseUrl: `/`,
  apiEndpoints: {
    register: `api/register`,
    emailExists: `api/email-exists`,
    mobileExists: `api/mobile-exists`
  },
  posthog: {
    token: `phc_vDeVxFAr1LkmiwUVDNm2yf1vbVkschK7CYhM6OTBAtY`,
    host: `https://us.i.posthog.com`,
    personProfiles: `identified_only`,
    unmaskedFields: [`text`]
  }
};
