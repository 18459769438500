export enum InputMode {
NONE = 'none',
TEXT = 'text',
DECIMAL = 'decimal',
NUMERIC = 'numeric',
TEL = 'tel',
SEARCH = 'search',
EMAIL = 'email',
URL = 'url',
PASSWORD = 'password',    
}
