<ion-input
  class="custom"
  [ngStyle]="{
    '--padding-start': (padding === false) ? (inputConfig.borderStyle === 'none' ? '0' : '1.1rem') : null,
    '--padding-end': (padding === false) ? (inputConfig.borderStyle === 'none' ? '0' : '0') : null,
    'width': width !== null ? width : '100%'
  }"
  [shape]="inputConfig.shape || 'round'"
  [fill]="inputConfig.fill || 'outline'"
  [type]="inputConfig.inputType"
  [placeholder]="inputConfig.placeHolder"
  [value]="value"
  [style.background]="inputConfig.backgroundColor || '#FFFFFF'" 
  [style.border-radius]="'1rem'"
  [style.--border-style]="inputConfig.borderStyle || 'solid'"
  [style.fontSize]="inputConfig.fontSize || 'inherit'"
  [style.fontWeight]="inputConfig.fontWeight || 'normal'"
  [style.textAlign]="inputConfig.textAlign || 'left'"
  [maxlength]="inputConfig.maxLength"
  (ionInput)="handleInput($event)"
  [inputMode]="inputMode"
  [disabled]="disabled">
</ion-input>
