import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-h3-heading',
  templateUrl: './h3-heading.component.html',
  styleUrls: ['./h3-heading.component.scss'],
})
export class H3HeadingComponent  implements OnInit {

  @Input() displayText: string = ``;
  @Input() isBold?: boolean;
  @Input() color?: string = `black`;
  constructor() { }

  ngOnInit() {
    console.debug(`[H3HeadingComponent][ngOnInit][To be Implemented later]`);
  }

}
