<div class="search-input-container">
  <ion-item class="search-item" lines="none">
    
    <!-- Search Icon on the Left -->
    <ion-icon name="search-outline" slot="start" class="search-icon"></ion-icon>
    
    <!-- Input Field with updated placeholder "Search" -->
    <ion-input
      placeholder="Search"
      inputmode="text"
      maxlength="30"
      type="text"
      (ionInput)="onSearchInputChange($event)"
      class="search-input">
    </ion-input>
    
  </ion-item>
</div>
