import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-homepage-header',
  templateUrl: './homepage-header.component.html',
  styleUrls: ['./homepage-header.component.scss'],
})
export class HomepageHeaderComponent  implements OnInit {
  @Input() navigationPath: string = ``;
  @Input() heading: string = ``;
  @Input() userName: string = ``;
  @Input() profileImage: string = ``;
  headingIsBold: boolean = true;



  constructor( private navigationController: NavController) { }

  ngOnInit() {
    console.log(`Homepage Header`);
  }

  navigateToNotifications() {
    this.navigationController.navigateForward(this.navigationPath);  // Navigate to notifications page
  }
}