import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

 /* TODO: Remove the inline initialization and import from the model */
type UserInformation = {
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  region: string;
  postalCode: string;
  mobileNumber?: string;
  referralCode?: string;
};

type InputType = `email` |  `password` | `text` | `tel`;

interface InputFieldConfig {
  label: string;
  placeHolder: string;
  inputType: InputType;
}

interface ProfileFormInputConfig {
  firstName: InputFieldConfig;
  middleName: InputFieldConfig;
  lastName: InputFieldConfig;
  dateOfBirth: InputFieldConfig;
  mobileNumber: InputFieldConfig;
  addressLine1: InputFieldConfig;
  addressLine2: InputFieldConfig;
  city: InputFieldConfig;
  region: InputFieldConfig;
  postalCode: InputFieldConfig;
  referralCode: InputFieldConfig;
}

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit, OnChanges {
  @Input() showMobileNumber: boolean = true;  
  @Input() showReferralCode: boolean = true;  
  @Input() isEditable: boolean = false;       
  @Input() dateOfBirthPattern!: RegExp;
  @Input() phonePattern!: RegExp;
  @Input() isProfileEdit: boolean = false;    
  @Input() userInformation?: UserInformation;  // Make it optional and add a guard later

  @Output() formSubmit: EventEmitter<UserInformation> = new EventEmitter<UserInformation>();  
  @Output() formValidity: EventEmitter<boolean> = new EventEmitter<boolean>();  

  profileForm!: FormGroup;
  inputConfig!: Observable<ProfileFormInputConfig>;

  constructor(private formBuilder: FormBuilder, public translate: TranslateService) {}

  ngOnInit(): void {
    this.initializeForm();
    this.toggleFormEditability();

    this.inputConfig = this.setTranslatedPlaceholders();

    // Ensure userInformation is defined before attempting to patch values
    if (this.isProfileEdit && this.userInformation) {
      this.patchUserInformation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If isEditable changes, toggle the form's editability
    if (changes[`isEditable`] && this.profileForm) {
      this.toggleFormEditability();
    }

    // Patch the user information if it changes and it's available
    if (changes[`userInformation`] && this.userInformation) {
      this.patchUserInformation();
    }
  }

  private setTranslatedPlaceholders(): Observable<any> {
    return this.translate.get([
      'PROFILE_FORM.FIRST_NAME',
      'PROFILE_FORM.MIDDLE_NAME',
      'PROFILE_FORM.LAST_NAME',
      'PROFILE_FORM.DATE_OF_BIRTH',
      'PROFILE_FORM.MOBILE_NUMBER',
      'PROFILE_FORM.ADDRESS1',
      'PROFILE_FORM.ADDRESS2',
      'PROFILE_FORM.CITY',
      'PROFILE_FORM.REGION',
      'PROFILE_FORM.POSTAL_CODE',
      'PROFILE_FORM.REFERRAL_CODE'
    ]).pipe(
      map(translations => ({
        firstName: { label: '', placeHolder: translations['PROFILE_FORM.FIRST_NAME'] , inputType: 'text' },
        middleName: { label: '', placeHolder: translations['PROFILE_FORM.MIDDLE_NAME'] , inputType: 'text' },
        lastName: { label: '', placeHolder: translations['PROFILE_FORM.LAST_NAME']  , inputType: 'text' },
        dateOfBirth: { label: '', placeHolder: translations['PROFILE_FORM.DATE_OF_BIRTH'] , inputType: 'text' },
        mobileNumber: { label: '', placeHolder: translations['PROFILE_FORM.MOBILE_NUMBER'] , inputType: 'tel' },
        addressLine1: { label: '', placeHolder: translations['PROFILE_FORM.ADDRESS1'] , inputType: 'text' },
        addressLine2: { label: '', placeHolder: translations['PROFILE_FORM.ADDRESS2'] , inputType: 'text' },
        city: { label: '', placeHolder: translations['PROFILE_FORM.CITY'] , inputType: 'text' },
        region: { label: '', placeHolder: translations['PROFILE_FORM.REGION'] , inputType: 'text' },
        postalCode: { label: '', placeHolder: translations['PROFILE_FORM.POSTAL_CODE'] , inputType: 'text' },
        referralCode: { label: '', placeHolder: translations['PROFILE_FORM.REFERRAL_CODE'] , inputType: 'text' },
      }))
    );
  }

  // Initialize the form with default values
  initializeForm(): void {
    this.profileForm = this.formBuilder.group({
      firstName: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      middleName: [{ value: ``, disabled: !this.isEditable }],
      lastName: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      dateOfBirth: [{ value: ``, disabled: !this.isEditable },  Validators.pattern(this.dateOfBirthPattern)],
      mobileNumber: [{ value: ``, disabled: !this.isEditable },  Validators.pattern(this.phonePattern)],
      addressLine1: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      addressLine2: [{ value: ``, disabled: !this.isEditable }],
      city: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      region: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      postalCode: [{ value: ``, disabled: !this.isEditable }, Validators.required],
      referralCode: [{ value: ``, disabled: !this.isEditable }],
    });

    // Emit form validity status when the form's status changes
    this.profileForm.statusChanges.subscribe(() => {
      this.formValidity.emit(this.profileForm.valid);
    });
  }

  // Patch user information into the form
  patchUserInformation(): void {
    if (this.userInformation && this.profileForm) {
      // Ensure userInformation exists before patching
      this.profileForm.patchValue(this.userInformation);
    }
  }

  // Toggle the form's editability based on the `isEditable` input
  toggleFormEditability(): void {
    if (this.profileForm) {
      if (this.isEditable) {
        this.profileForm.enable();
      } else {
        this.profileForm.disable();
      }
    }
  }

  // Submit the form when it's valid
  submitForm(): void {
    if (this.profileForm.valid) {
      this.formSubmit.emit(this.profileForm.value);
    } else {
      this.profileForm.markAllAsTouched();  // Mark all fields as touched to show validation errors
    }
  }

  // Check if a form field is invalid
  isFieldInvalid(field: string): boolean {
    const control: AbstractControl | null = this.profileForm.get(field);
    return control ? control.invalid && control.touched : false;
  }

  // Get error message for form fields
  getErrorMessage(field: string): string {
    const control: AbstractControl | null = this.profileForm.get(field);
    if (control?.hasError(`required`)) {
      return `This field is required.`;
    }
    if (control?.hasError(`pattern`)) {
      return `Invalid format.`;
    }
    return ``;
  }

  // Access the dateOfBirth form control
  get dateOfBirthControl(): FormControl {
    return this.profileForm.get(`dateOfBirth`) as FormControl;
  }
}