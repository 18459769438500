<form *ngIf="inputConfig | async as inputConfig" [formGroup]="profileForm" (ngSubmit)="submitForm()">
  <ion-grid>
    <ion-row>
      <ion-col>
        <!-- First Name Field -->
        <app-input
          [inputConfig]="inputConfig.firstName"
          formControlName="firstName"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('firstName')" 
          *ngIf="isFieldInvalid('firstName')"
          [padding]="false">
        </app-message>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <!-- Middle Name Field -->
        <app-input
          [inputConfig]="inputConfig.middleName"
          formControlName="middleName"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
      </ion-col>
      <ion-col>
        <!-- Last Name Field -->
        <app-input
          [inputConfig]="inputConfig.lastName"
          formControlName="lastName"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('lastName')" 
          *ngIf="isFieldInvalid('lastName')"
          [padding]="false">
        </app-message>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <!-- Date of Birth Field -->
        <app-date-time-input 
          [control]="dateOfBirthControl" 
          [placeHolder]="inputConfig.dateOfBirth.placeHolder"
          [presentation]="'date'" 
          [preferWheel]="true"
          [disabled]="!isEditable">
        </app-date-time-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('dateOfBirth')" 
          *ngIf="isFieldInvalid('dateOfBirth')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- Mobile Number Field (Optional) -->
    <ion-row *ngIf="showMobileNumber">
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.mobileNumber"
          formControlName="mobileNumber"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('mobileNumber')" 
          *ngIf="isFieldInvalid('mobileNumber')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- Address Line 1 -->
    <ion-row>
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.addressLine1"
          formControlName="addressLine1"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('addressLine1')" 
          *ngIf="isFieldInvalid('addressLine1')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- Address Line 2 (Optional) -->
    <ion-row>
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.addressLine2"
          formControlName="addressLine2"
          [disabled]="!isEditable"
          [padding]="false"
          >
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('addressLine2')" 
          *ngIf="isFieldInvalid('addressLine2')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- City and Region -->
    <ion-row>
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.city"
          formControlName="city"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('city')" 
          *ngIf="isFieldInvalid('city')">
        </app-message>
      </ion-col>
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.region"
          formControlName="region"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('region')" 
          *ngIf="isFieldInvalid('region')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- Postal Code -->
    <ion-row>
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.postalCode"
          formControlName="postalCode"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
        <app-message 
          [messageType]="'error'" 
          [message]="getErrorMessage('postalCode')" 
          *ngIf="isFieldInvalid('postalCode')">
        </app-message>
      </ion-col>
    </ion-row>

    <!-- Referral Code Field (Optional) -->
    <ion-row *ngIf="showReferralCode">
      <ion-col>
        <app-input
          [inputConfig]="inputConfig.referralCode"
          formControlName="referralCode"
          [disabled]="!isEditable"
          [padding]="false">
        </app-input>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
