import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
/* TODO: Remove this initialization and import from the model */
type BankAccount = {
  isPrimary: boolean;
  bankName: string;
  accountNumber: string;
  nickName: string;
  bankLogo?: string;
  balance?: string;
};

@Component({
  selector: 'app-bank-list-dropdown',
  templateUrl: './bank-list-dropdown.component.html',
  styleUrls: ['./bank-list-dropdown.component.scss'],
})
export class BankListDropdownComponent implements OnChanges {
  @Input() bankList: BankAccount[] = [];  // Receive the bank list from parent component
  filteredBankList: BankAccount[] = [];  // Filtered list excluding the selected bank
  selectedBank: BankAccount | undefined = undefined;
  dropdownOpen: boolean = false;

  @Output() bankSelected: EventEmitter<BankAccount> = new EventEmitter<BankAccount>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['bankList'] && this.bankList.length > 0) {
      // Format the account numbers before displaying
      this.bankList = this.bankList.map((bank: BankAccount): BankAccount => ({
        ...bank,
        accountNumber: this.formatAccountNumber(bank.accountNumber),
      }));

      this.selectedBank = this.bankList.find((bank: BankAccount): boolean => bank.isPrimary) || this.bankList[0];
      this.updateFilteredBankList();  // Update filtered list
      this.emitSelectedBank();  // Emit the initially selected bank
    }
  }

  private emitSelectedBank(): void {
    if (this.selectedBank) {
      this.bankSelected.emit(this.selectedBank);
    }
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onBankSelect(bank: BankAccount): void {
    this.selectedBank = bank;
    this.updateFilteredBankList();  // Update filtered list after selection
    this.dropdownOpen = false;  // Close the dropdown after selection
    this.emitSelectedBank();  // Emit the selected bank
  }

  private updateFilteredBankList(): void {
    this.filteredBankList = this.bankList.filter((bank: BankAccount): boolean => bank !== this.selectedBank);
  }

  private formatAccountNumber(accountNumber: string): string {
    const regexPattern: RegExp = /^(\d{2})(\d{4})(\d{8})(\d{2,3})$/;
    return accountNumber.replace(regexPattern, `$1 $2 $3 $4`);
  }
}
