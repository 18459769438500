<ion-input
  class="custom"
  [ngStyle]="{
    '--padding-start': padding === false ? (inputConfig.borderStyle === 'none' ? paddingEndValue : paddingStartValue) : null,
    '--padding-end': padding === false ? paddingEndValue : null,
    'width': width !== null ? width : defaultWidth
  }"
  [shape]="inputConfig.shape || 'round'"
  [fill]="inputConfig.fill || 'outline'"
  [type]="inputConfig.inputType"
  [placeholder]="inputConfig.placeHolder"
  [value]="value"
  [style.background]="inputConfig.backgroundColor || defaultBackgroundColor" 
  [style.border-radius]="defaultBorderRadius"
  [style.--border-style]="inputConfig.borderStyle || defaultBorderStyle"
  [style.fontSize]="inputConfig.fontSize || defaultFontSize"
  [style.fontWeight]="inputConfig.fontWeight || defaultFontWeight"
  [style.textAlign]="inputConfig.textAlign || defaultTextAlign"
  [maxlength]="inputConfig.maxLength"
  (ionInput)="handleInput($event)"
  [inputMode]="inputMode"
  [disabled]="disabled">
</ion-input>
