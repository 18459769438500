<div class="datetime-container">
<app-input 
    [inputConfig]="{ label: '', placeHolder: placeHolder, inputType: 'text'}" 
    [formControl]="control"
    [disabled]="disabled"
    [padding]="false">
  </app-input> 
  <ion-icon  id="datepicker" name="calendar-outline" class="calendar-toggle-icon"></ion-icon>
<ion-popover trigger="datepicker" triggerAction="click" side="left" alignment="start">
  <ng-template>
 <ion-content>
    <ion-datetime   
    [value]="control.value ? control.value : null"
    (ionChange)="onDateSelected($event)"
    [presentation]="presentation" 
    [preferWheel]="preferWheel"     
    [disabled]="disabled"  
    size="cover"
    ></ion-datetime>
  </ion-content>
  </ng-template> 
</ion-popover>    
</div>
<app-message [messageType]="'error'" [message]="getErrorMessage()" *ngIf="isFieldInvalid()"></app-message>