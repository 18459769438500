<ion-list class="transaction-list">
  <ion-item *ngFor="let transaction of transactions" class="transaction-item">
    <app-user-profile-card 
      [heading]="transaction.title" 
      [description]="transaction.date + dateAndTimeSpacing + transaction.time"
      [profileImage]="transaction.profileImage"
      [descriptionColor]="descriptionColor"
      [descriptionFontSize]="descriptionFontSize"
      [headingFontSize]="headingFontSize"
      [firstName]="transaction.firstName!"
      [lastName]="transaction.lastName!">
    </app-user-profile-card>
    <app-transaction-amount 
      [amount]="transaction.amount" 
      [transactionType]="transaction.transactionType">
    </app-transaction-amount>
  </ion-item>
</ion-list>
