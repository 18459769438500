import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent {
  searchInput: string = ``;  // Holds the search input value

  @Output() contactSearch: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  /**
   * @description Handles input changes and triggers contact search.
   * @param event - The input event from the search input field.
   */
  onSearchInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;  // Get the input value
    this.searchInput = input;
    this.searchContacts();  // Trigger search when input changes
  }

  /**
   * @description Emits the search input value to filter contacts.
   */
  searchContacts(): void {
    const searchText = this.searchInput.trim();  // Clean the search input
    console.log(`Emitting Search Text:`, searchText);
    this.contactSearch.emit(searchText);  // Emit the search term
  }
}
