import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  @Input() heading: string = ``;
  @Input() description: string = ``;
  headingFontSize: string = `0.938rem`;

  constructor() { }

  ngOnInit() {
    console.log(`Header Component`);
  }

}
