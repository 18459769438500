export enum AppRoutes {
    HOME = `home-page`,
    ONBOARDING = `onboarding`,
    REGISTRATION = `registration`,
    PROFILE_SETUP = `profile-setup`,
    CODE_VERIFICATION = `code-verification`,
    PERSONAL_DETAILS = `personal-details`,
    SETUP_PIN = `setup-pin`,
    RE_ENTER_PIN = `re-enter-pin`,
    SPLASH_SCREEN = `splash-screen`,
    SIGN_IN_ACCOUNT_DETAILS = `sign-in-account-details`,
    SIGN_IN_VERIFICATION = `sign-in-verification`,   
    LOGIN_EMAIL = `login-email`,
    LOGIN_PIN = `login-pin`,
    THANK_YOU = `thank-you`,
    PAYMENT = `payment`,
    PAYMENT_COMPLETE = `payment-complete`,
    PAYMENT_PIN = `payment-pin`,
    BANK = `bank`,    
    ADD_BANK = `add-bank`,
    BANK_DELETE_PIN = `bank-delete-pin`,
    BANK_SET_PRIMARY_PIN = `bank-set-primary-pin`,   
    PAYMENT_CONTACT = `payment-contact`,
    PAYMENT_QR_CODE = `payment-qr-code`,
    TRANSACTIONS = `transactions`,  
    NOTIFICATIONS = `notifications`, 
    SEND_REQUEST = `send-request`,
    RECEIVE_REQUEST = `receive-request`,
    PROFILE = `profile`
  }