<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header class="header">
        <!-- Back Icon to Close Menu -->
        <ion-buttons slot="start">
          <ion-button (click)="closeMenu()">
            <ion-icon slot="icon-only" name="arrow-back-outline" class="back-icon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-header>

      <ion-content>

        <!-- Profile Section with QR Code -->
        <div class="profile-section">
          <!-- User Profile Card -->
          <app-user-profile-card
            heading="Mayur Agrawal"
            description="ID: mayur876&#64;wallo"
            profileImage="assets/images/user-avatar.png"
            descriptionColor="#2B6EFE"
            [descriptionIsBold]="true"
            [descriptionFontSize]="'0.875rem'">
          </app-user-profile-card>

          <!-- QR Code Section -->
          <div class="qr-section">
            <img src="assets/images/mock-qr-code.png" alt="QR Code" class="qr-image" />
            <app-description [displayText]="'ANZ Bank'"></app-description>
          </div>

          <!-- QR Code Action Buttons -->
          <div class="qr-buttons">
            <ion-button expand="block" fill="outline" class="custom-outline-button">
              <div class="button-content">
                <ion-icon name="share-social-outline" class="qr-icon"></ion-icon>
                <span class="button-text">Share QR</span>
              </div>
            </ion-button>
            <ion-button expand="block" fill="outline" class="custom-outline-button">
              <div class="button-content">
                <ion-icon name="download-outline" class="qr-icon"></ion-icon>
                <span class="button-text">Download QR</span>
              </div>
            </ion-button>
          </div>
          
          
          
        

        <!-- Menu Items -->
        <ion-list id="menu-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon aria-hidden="true" slot="start" [name]="p.icon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
