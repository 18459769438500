<ion-grid>
  <ion-row>
    <ion-col *ngFor="let icon of icons" class="icon-col">
      <app-icon 
        [iconName]="icon.iconName"      
        [bgColor]="icon.bgColor" 
        [text]="icon.text"
        [isActive]="isActive(icon.text)"
        (iconClick)="onIconClick($event)">
      </app-icon>
    </ion-col>
  </ion-row>
</ion-grid>
