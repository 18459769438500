import { Component, Input, OnInit } from '@angular/core';

 /* TODO: Remove the inline initialization and import from the model */
type Transaction = {
  title: string;
  profileImage: string;
  date: string;
  time: string;
  amount: number;
  transactionType?: `credit` | `debit`; 
  firstName?: string;
  lastName?: string;
};

type Notification = {
  type: `transaction` | `notification`;
  transaction?: Transaction; // Optional for transaction notifications
  title?: string; // Title for non-transaction notifications
  date?: string; // Optional date for non-transaction notifications
  profileImage?: string; // Optional profile image for non-transaction notifications
  message?: string; // Optional message for non-transaction notifications
  firstName?: string; 
  lastName?: string; 
};

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  @Input() notifications: Notification[] = []; // Notifications will be passed from the parent
  @Input() userId: string = ``; // Input the userId from the parent component

  ngOnInit() {
    console.log(`Notifications initialized:`, this.notifications);
  }
}
