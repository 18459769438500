<div class="header">
  <div class="header-content">
    <!-- Left: Profile Picture -->
    <div class="profile-container">
      <ion-avatar class="profile-avatar">
        <img [src]="profileImageUrl" alt="Profile Pic">
      </ion-avatar>

      <div class="camera-frame" (click)="onCameraIconClick()">
        <img [src]="cameraIconUrl" alt="Camera Icon" class="camera-icon">
      </div>
    </div>

    <!-- Right: User Info and Button -->
    <div class="info-container">
      <!-- User Name -->
      <app-h3-heading [displayText]="userName" [isBold]="true" [color]="userNameColor"></app-h3-heading>
      <!-- Wallo ID -->
      <app-description [displayText]="'ID: ' + walloId" [color]="walloIdColor" class="wallo-id" [fontSize]="walloIdFontSize"></app-description>
      <!-- Edit Button -->
      <app-button 
        [displayText]="editButtonText"        
        [width]="editButtonWidth"
        [height]="editButtonHeight"
        [fontSize]="editButtonFontSize"
        [buttonCss]="editButtonCssClass"
        (buttonClick)="onEdit()">
      </app-button>
    </div>
  </div>
</div>
