<div class="homepage-header">
  <ion-buttons slot="start" class="header-class">
    <ion-menu-button autoHide="false" class="large-menu-icon"></ion-menu-button>
  </ion-buttons> 
 
  <app-user-profile-card    
    heading="Welcome Back!" 
    description="Mayur" 
    profileImage="assets/images/user-avatar.png"
    [headingBold]="true">
  </app-user-profile-card>

  <!-- Header Icons Section -->
  <div class="header-icons">
    <ion-icon name="search-outline"></ion-icon>
    <ion-icon name="notifications-outline"  (click)="navigateToNotifications()"></ion-icon>
  </div>
</div>
