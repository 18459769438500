import { Component, Input, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-manage-bank-popover',
  templateUrl: './manage-bank-popover.component.html',
})
export class ManageBankPopoverComponent implements AfterViewInit {
  /* TODO: Remove the inline initialization and import from the model */
  @Input() bank!: { 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  };   

  @Input() totalBankAccounts: number = 1; // Input to know the total number of bank accounts
  @Output() deleteBankAccount: EventEmitter<{ 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  }> = new EventEmitter(); // Output to emit delete event

  @Output() setAsPrimary: EventEmitter<void> = new EventEmitter<void>();
  @Output() editBankAccount: EventEmitter<{ 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  }> = new EventEmitter(); 

  userId: string = `user123`; // Hardcoded user ID for this mock setup

  constructor(
    private popoverController: PopoverController,   
    private renderer: Renderer2,    
  ) {}

  ngAfterViewInit(): void {
    this.setBackdropInert();
    this.focusFirstElement();
  }

  private setBackdropInert(): void {
    const backdrop: HTMLElement | null = document.querySelector(`ion-backdrop`);
    if (backdrop) {
      this.renderer.setAttribute(backdrop, `inert`, `true`);
    }
  }

  private focusFirstElement(): void {
    const firstFocusable: HTMLElement | null = document.querySelector(`ion-item[button]`);
    if (firstFocusable) {
      firstFocusable.focus();
    }
  }

  isDeleteDisabled(): boolean {
    return this.totalBankAccounts <= 1 || this.bank.isPrimary;
  }

  onSetAsPrimary(): void {
    if (!this.bank.isPrimary) {
      this.setAsPrimary.emit(); // Emit the event to parent
      this.popoverController.dismiss({ action: `setAsPrimary`, bank: this.bank });
    }
  }

  onEdit(): void {
    this.popoverController.dismiss({ action: `edit`, bank: this.bank }).then(() => {
      this.editBankAccount.emit(this.bank); // Emit the edit event to parent
    });
  }

  onDelete(): void {
    if (!this.isDeleteDisabled()) {
      console.log(`Delete clicked for bank:`, this.bank);
      this.popoverController.dismiss({ action: `delete`, bank: this.bank }).then(() => {
        this.deleteBankAccount.emit(this.bank); // Emit delete event to the parent component
      });
    }
  }
}