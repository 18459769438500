<div class="bank-dropdown">
  <div class="selected-bank" (click)="toggleDropdown()">
    <img [src]="selectedBank?.bankLogo" alt="Bank Logo" class="bank-logo" />
    <div class="bank-info">
      <app-description [displayText]="selectedBank?.nickName!" [fontSize]="'1rem'" [isBold]="true" [color]="'black'"></app-description>
      <app-description [displayText]="selectedBank?.accountNumber!" [color]="'#666666'" [fontSize]="'0.875rem'"></app-description>
    </div>
    <ion-icon name="chevron-down-outline"></ion-icon>
  </div>

  <div class="bank-list" *ngIf="dropdownOpen">
    <div *ngFor="let bank of filteredBankList" (click)="onBankSelect(bank)" class="bank-item">
      <img [src]="bank.bankLogo" alt="Bank Logo" class="bank-logo" />
      <div class="bank-info">
        <app-description [displayText]="bank.nickName!" [fontSize]="'1rem'" [isBold]="true" [color]="'black'"></app-description>
        <app-description [displayText]="bank.accountNumber!" [color]="'#666666'" [fontSize]="'0.875rem'"></app-description>
      </div>
    </div>
  </div>
</div>
