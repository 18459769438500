<swiper-container class="mySwiper" effect="coverflow" loop="true" pagination>
  <swiper-slide *ngFor="let bank of bankList">
    <div class="card-content">
      <div class="card-background">
        <img src="assets/images/user-card.png" alt="User Card Background" class="card-image" />
      </div>
      <div class="card-details">
        
        <!-- Align Primary label and Total Balance text on the same line -->
        <div class="card-header">        
          <app-description [displayText]="'Total Balance'" [color]="'white'" class="total-balance-label" [fontSize]="'0.813rem'"></app-description>
          
          <!-- Show/Hide Primary label using CSS visibility -->
          <app-description [displayText]="'Primary'" [color]="'white'" [fontSize]="'0.75rem'" [style.visibility]="bank.isPrimary ? 'visible' : 'hidden'" class="primary-label"></app-description>
        </div>
        
        <!-- Balance amount displayed below the header -->
        <app-h2-heading [displayText]="bank.balance || '0'" class="total-balance" [color]="'white'" [isBold]="true" [fontSize]="'1.5rem'"></app-h2-heading>
        
        <div class="bank-details">
          <!-- Nickname, Bankname and account number -->
          <app-description [displayText]="bank.bankName" [color]="'white'" [fontSize]="'0.875rem'" [isBold]="true"></app-description>
          <app-description [displayText]="bank.nickName" [color]="'white'" [fontSize]="'0.75rem'"></app-description>      
          <app-description [displayText]="formatAccountNumber(bank.accountNumber)" [color]="'white'" [fontSize]="'0.875rem'" class="account-number" [isBold]="true"></app-description>
        </div>       
      </div>
    </div>
  </swiper-slide>
</swiper-container>
