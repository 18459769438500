import { Component, Input, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
 /* TODO: Remove the inline initialization and import from the model */
enum BankActions {
  SET_AS_PRIMARY = 'setAsPrimary',
  EDIT = 'edit',
  DELETE = 'delete',
}

interface BankActionItem {
  label: string; // Display text
  action: BankActions; // Corresponding action enum
  isDisabled: () => boolean; // Function to determine if the action is disabled
}

@Component({
  selector: 'app-manage-bank-popover',
  templateUrl: './manage-bank-popover.component.html',
})
export class ManageBankPopoverComponent implements AfterViewInit {
  /* TODO: Remove the inline initialization and import from the model */
  @Input() bank!: { 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  };   

  @Input() totalBankAccounts: number = 1; // Input to know the total number of bank accounts
  @Output() deleteBankAccount: EventEmitter<{ 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  }> = new EventEmitter(); // Output to emit delete event

  @Output() setAsPrimary: EventEmitter<{ 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  }> = new EventEmitter();
  @Output() editBankAccount: EventEmitter<{ 
    isPrimary: boolean; 
    bankName: string; 
    accountNumber: string; 
    nickName: string; 
    bankLogo?: string; 
    balance?: string; 
  }> = new EventEmitter(); 

  userId: string = `user123`; // Hardcoded user ID for this mock setup
  public BankActions = BankActions;

    // Dynamic list of actions with a specific type
    actions: BankActionItem[] = [
      {
        label: 'Set as Primary',
        action: BankActions.SET_AS_PRIMARY,
        isDisabled: () => this.bank.isPrimary, // Disable if the bank is already primary
      },
      {
        label: 'Edit',
        action: BankActions.EDIT,
        isDisabled: () => false, // Always enabled
      },
      {
        label: 'Delete',
        action: BankActions.DELETE,
        isDisabled: () => this.isDeleteDisabled(), // Disable if only one bank or the bank is primary
      },
    ];

  constructor(
    private popoverController: PopoverController,   
    private renderer: Renderer2,    
  ) {}

  ngAfterViewInit(): void {
    this.setBackdropInert();
    this.focusFirstElement();
  }

  private setBackdropInert(): void {
    const backdrop: HTMLElement | null = document.querySelector(`ion-backdrop`);
    if (backdrop) {
      this.renderer.setAttribute(backdrop, `inert`, `true`);
    }
  }

  private focusFirstElement(): void {
    const firstFocusable: HTMLElement | null = document.querySelector(`ion-item[button]`);
    if (firstFocusable) {
      firstFocusable.focus();
    }
  }

  isDeleteDisabled(): boolean {
    return this.totalBankAccounts <= 1 || this.bank.isPrimary;
  }

  performAction(action: BankActions): void {
    const data = { action, bank: this.bank };
    this.popoverController.dismiss(data).then(() : void => {
      switch (action) {
        case BankActions.DELETE:
          this.deleteBankAccount.emit(this.bank);
          break;
        case BankActions.SET_AS_PRIMARY:
          this.setAsPrimary.emit(this.bank);
          break;
        case BankActions.EDIT:
          this.editBankAccount.emit(this.bank);
          break;
      }
    });
  }
}