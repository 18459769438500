import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
 /* TODO: Remove this initialization and import from the model */
type PayeeUserVerification = {
  walloId: string;
  userId: string;
  profilePic: string | null; // Null if no profile picture is available
  firstName: string;
  lastName: string;
  mobileNumber: string; // Newly added field for mobile number
};

@Component({
  selector: `app-contact-list`,
  templateUrl: `./contact-list.component.html`,
  styleUrls: [`./contact-list.component.scss`],
})
export class ContactListComponent implements OnInit, OnChanges {
  @Input() contacts: PayeeUserVerification[] = [];
  @Input() filterText: string = ``; // Input to filter contacts
  @Input() title: string = ``; // Title for the contact list 
  filteredContacts: PayeeUserVerification[] = []; // Filtered contacts to display

  @Output() contactSelect: EventEmitter<string> = new EventEmitter<string>(); // Emit only the contact number

  constructor(   
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * @description Initializes the component and loads contacts.
   */
  ngOnInit(): void {
    console.log(`Contact List Page`);
  }

 /**
 * @description Detects changes in input properties.
 * @param changes - The changes object containing the new and old values of input properties.
 */
ngOnChanges(changes: SimpleChanges): void {
  // Trigger filtering if either filterText or contacts change
  if ((changes[`filterText`] && !changes[`filterText`].firstChange) || changes[`contacts`]) {
    console.log(`Filter Text or Contacts Changed`);
    this.filterContacts(this.filterText); // Re-filter contacts based on updated filterText or contact list
  }
}


  

  /**
   * @description Filters contacts based on the search text.
   * @param searchText - The text to filter contacts.
   */
  filterContacts(searchText: string): void {
    console.log(`Filtering Contacts with:`, searchText); // Log search text used for filtering
    const lowerFilter: string = searchText.toLowerCase().trim(); // Convert filter text to lowercase for case-insensitive matching

    if (lowerFilter.length > 0) {
      this.filteredContacts = this.contacts.filter((contact: PayeeUserVerification) => {
        const fullName: string = `${contact.firstName} ${contact.lastName}`.toLowerCase();
        const mobileNumber: string = contact.mobileNumber.toLowerCase();
        const walloId: string = contact.walloId.toLowerCase(); // Updated field name
        
        return (
          fullName.includes(lowerFilter) || // Match by full name (first + last)
          mobileNumber.includes(lowerFilter) || // Match by mobile number
          walloId.includes(lowerFilter) // Match by Wallo ID (previously userName)
        );
      });
    } else {
      this.filteredContacts = this.contacts; // If no search text, show all contacts
    }

    console.log(`Filtered Contacts:`, this.filteredContacts); // Log filtered contacts
    this.cdr.detectChanges(); // Force change detection after filtering
  }

  /**
   * @description Handles the selection of a contact and emits the contact number.
   * @param contact - The selected contact.
   */
  onContactSelect(contact: PayeeUserVerification): void {
    console.log(`Contact selected:`, contact);
    this.contactSelect.emit(contact.mobileNumber); // Emit only the contact number
  }
}
