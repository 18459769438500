<ion-button
  expand="block"
  [shape]="buttonShape"
  [type]="buttonType"
  [ngClass]="buttonCss"
  [ngStyle]="{
    'background-color': disabled ? disabledBackgroundColor : buttonColor,
    'color': disabled ? disabledColor : (buttonColor === defaultColor ? disabledColor : defaultColor),
    'border': buttonColor === defaultColor ? buttonBorder : 'none',
    'width': width,
    'height': height,
    'font-size': fontSize
  }"
  [disabled]="disabled"
  (click)="onClick()"
>
   <!-- Display the icon if provided -->
   <ion-icon *ngIf="icon" [name]="icon" slot="start"></ion-icon>
  
  <!-- Display Text -->
  {{ displayText }}
</ion-button>
