<h2
  [ngStyle]="{
    'font-weight': isBold ? boldFontWeight : defaultFontWeight,
    'color': color,
    'font-size': fontSize
  }"
  class="ion-text-wrap h2-heading"
>
  {{ displayText }}
</h2>
