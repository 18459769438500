import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent  implements OnInit {

  @Input() userName: string = ``;
  @Input() walloId: string = ``;
  @Input() profileImageUrl: string = ``;
  @Input() editButtonText: string = ``;

   // Emit event to parent when the Edit button is clicked
   @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
    console.log(`ProfileHeaderComponent`);
  }
  onEdit(): void {   
    console.log(`Edit button clicked`);
    this.editClicked.emit(); // Emit the event to the parent component
  }

  onCameraIconClick(): void {
    // Logic for handling camera icon click will be implemented later
    console.log(`Camera icon clicked`);
  }

}
