<app-header 
  [heading]="heading" 
  [description]="description">
</app-header>    

<form [formGroup]="formGroup" (ngSubmit)="onSubmit('btn_signin_acct_dtls')">  
  <app-input
    inputmode="email"
    [inputConfig]="{ label: '', placeHolder: 'Email Address', inputType: 'email', rows: 1 }"
    formControlName="email"
  ></app-input>
  <!-- Display errors for email -->
  <app-message
    [messageType]="'error'"
    [message]="getErrorMessage('email')"
    *ngIf="isFieldInvalid('email')"
  ></app-message>      
  <div class="password-container">
    <app-input
      [inputConfig]="{
        label: '',
        placeHolder: 'Password',
        inputType: showPassword ? 'text' : 'password',
        rows: 1
      }"
      formControlName="password"
    ></app-input>
    <ion-icon
      name="{{ showPassword ? 'eye-off-outline' : 'eye-outline' }}"
      class="password-toggle-icon"
      (click)="togglePasswordVisibility()"
    ></ion-icon>
  </div>
  <a class="forgot-password-link" (click)="onForgotPassword()">Forgot Password?</a>
  <!-- Display errors for password -->
  <app-message
    [messageType]="'error'"
    [message]="getErrorMessage('password')"
    *ngIf="isFieldInvalid('password')"
  ></app-message>    
</form>