import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-h2-heading',
  templateUrl: './h2-heading.component.html',
  styleUrls: ['./h2-heading.component.scss'],
})
export class H2HeadingComponent  implements OnInit {

  @Input() displayText: string = ``;
  @Input() isBold: boolean = false; // Bold text
  @Input() color: string = `#000000`; // Default color
  @Input() fontSize: string = `1.25rem`; // Default font size

  // Predefined styles for the heading
  defaultFontWeight: string = `normal`; // Default font weight
  boldFontWeight: string = `bold`; // Bold font weight

  constructor() { }

  ngOnInit() {
    console.debug(`[H2HeadingComponent][ngOnInit][To be Implemented later]`);
  }

}
