import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MessageType } from './message-type.enum';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent  implements OnInit, AfterViewInit, OnDestroy {
  @Input() messageType!: string;
  @Input() message!: SafeHtml;
  @Input() isBold?: boolean;
  icon: string = ``;
  iconColor: string = ``;
  messageColor: string = ``;

  constructor() {}

  ngOnInit(): void {
    console.debug(`Ng on init called`)
    switch (this.messageType) {
      case MessageType.ALERT:
        this.icon = `alert-circle-outline`
        this.iconColor = `blue`
        console.debug(`Alert message type ${this.messageType} icon: ${this.icon}`)
        break;
      case MessageType.ERROR:
        this.icon = `alert-circle-outline`
        this.iconColor = `danger`
        this.messageColor = `#FF0000`
        console.debug(`Alert message type ${this.messageType} icon: ${this.icon}`)
        break; 
      case MessageType.NOTICE:
        this.icon = `pin-outline`
        console.debug(`Alert message type ${this.messageType} icon: ${this.icon}`)
        break;
      case MessageType.DISPLAY:
        console.debug(`Alert message type ${this.messageType} icon: ${this.icon}`)
        break;
      default:
        console.debug(`Alert message type ${this.messageType} icon: ${this.icon}`)
        throw new Error(`Incorrect message type provided ${this.messageType}`)
    }
  }

  ngAfterViewInit(): void {
     console.log(`after view init`)
  }

  ngOnDestroy(): void {
    console.log(`on destroy`)
  }
}
