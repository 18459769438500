import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-amount',
  templateUrl: './transaction-amount.component.html',
  styleUrls: ['./transaction-amount.component.scss'],
})
export class TransactionAmountComponent  implements OnInit {
  @Input() amount: number = 0;
  @Input() transactionType?: string; // Optional Input for transaction type

  constructor() { }

    get amountColor(): string {
    if (this.transactionType) {
      return this.transactionType ===   `credit` ? `#46B051` : `#F22727`;
    }
    return this.amount > 0 ? `#46B051` : `#F22727`;
  }

  ngOnInit() {
    console.log(`TransactionAmountComponent`);
  }

}
