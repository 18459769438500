import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './app-routes.enum';

const routes: Routes = [
  {
    path: '', // Default route
    redirectTo: AppRoutes.SPLASH_SCREEN, // Redirect to splash-screen as the initial route
    pathMatch: 'full',
  },
  {
    path: AppRoutes.SPLASH_SCREEN,
    loadChildren: () =>
      import('./pages/splash-screen/splash-screen.module').then((m) => m.SplashScreenPageModule),
  },
  {
    path: AppRoutes.ONBOARDING,
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((module) => module.FolderPageModule),
  },
  {
    path: AppRoutes.PROFILE_SETUP,
    loadChildren: () =>
      import('./pages/sign-up/profile-setup/profile-setup.module').then((m) => m.ProfileSetupPageModule),
  },
  {
    path: AppRoutes.CODE_VERIFICATION,
    loadChildren: () =>
      import('./pages/sign-up/code-verification/code-verification.module').then((m) => m.CodeVerificationPageModule),
  },
  {
    path: AppRoutes.PERSONAL_DETAILS,
    loadChildren: () =>
      import('./pages/sign-up/personal-details/personal-details.module').then((m) => m.PersonalDetailsPageModule),
  },
  {
    path: AppRoutes.SETUP_PIN,
    loadChildren: () =>
      import('./pages/sign-in/setup-pin/setup-pin.module').then((m) => m.SetupPinPageModule),
  },
  {
    path: AppRoutes.RE_ENTER_PIN,
    loadChildren: () =>
      import('./pages/sign-in/re-enter-pin/re-enter-pin.module').then((m) => m.ReEnterPinPageModule),
  },
  {
    path: AppRoutes.SIGN_IN_ACCOUNT_DETAILS,
    loadChildren: () =>
      import('./pages/sign-in/sign-in-account-details/sign-in-account-details.module').then((m) => m.SignInAccountDetailsPageModule),
  },
  {
    path: AppRoutes.SIGN_IN_VERIFICATION,
    loadChildren: () =>
      import('./pages/sign-in/sign-in-verification/sign-in-verification.module').then((m) => m.SignInVerificationPageModule),
  },
  {
    path: AppRoutes.LOGIN_EMAIL,
    loadChildren: () =>
      import('./pages/log-in/log-in-email/log-in-email.module').then((m) => m.LogInEmailPageModule),
  },
  {
    path: AppRoutes.LOGIN_PIN,
    loadChildren: () =>
      import('./pages/log-in/log-in-pin/log-in-pin.module').then((m) => m.LogInPinPageModule),
  },
  {
    path: AppRoutes.THANK_YOU,
    loadChildren: () =>
      import('./pages/sign-up/thank-you/thank-you.module').then((m) => m.ThankYouPageModule),
  },
  {
    path: AppRoutes.HOME,
    loadChildren: () =>
      import('./pages/home-page/home-page/home-page.module').then((m) => m.HomePagePageModule),
  },
  {
    path: AppRoutes.PAYMENT,
    loadChildren: () =>
      import('./pages/home-page/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: AppRoutes.PAYMENT_PIN,
    loadChildren: () =>
      import('./pages/home-page/payment-pin/payment-pin.module').then((m) => m.PaymentPinPageModule),
  },
  {
    path: AppRoutes.BANK,
    loadChildren: () =>
      import('./pages/home-page/bank/bank.module').then((m) => m.BankPageModule),
  },
  {
    path: AppRoutes.ADD_BANK,
    loadChildren: () =>
      import('./pages/home-page/add-bank/add-bank.module').then((m) => m.AddBankPageModule),
  },
  {
    path: AppRoutes.BANK_DELETE_PIN,
    loadChildren: () =>
      import('./pages/home-page/bank-delete-pin/bank-delete-pin.module').then((m) => m.BankDeletePinPageModule),
  },
  {
    path: AppRoutes.BANK_SET_PRIMARY_PIN,
    loadChildren: () =>
      import('./pages/home-page/bank-set-primary-pin/bank-set-primary-pin.module').then((m) => m.BankSetPrimaryPinPageModule),
  },
  {
    path: AppRoutes.PAYMENT_CONTACT,
    loadChildren: () =>
      import('./pages/home-page/payment-contact/payment-contact.module').then((m) => m.PaymentContactPageModule),
  },
  {
    path: AppRoutes.PAYMENT_QR_CODE,
    loadChildren: () =>
      import('./pages/home-page/payment-qr-code/payment-qr-code.module').then((m) => m.PaymentQrCodePageModule),
  },
  {
    path: AppRoutes.TRANSACTIONS,
    loadChildren: () => import('./pages/home-page/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: AppRoutes.NOTIFICATIONS,
    loadChildren: () => import('./pages/home-page/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: AppRoutes.SEND_REQUEST,
    loadChildren: () => import('./pages/home-page/send-request/send-request.module').then( m => m.SendRequestPageModule)
  },
  {
    path: AppRoutes.RECEIVE_REQUEST,
    loadChildren: () => import('./pages/home-page/receive-request/receive-request.module').then( m => m.ReceiveRequestPageModule)
  },
  {
    path: AppRoutes.PROFILE,
    loadChildren: () => import('./pages/home-page/profile/profile.module').then( m => m.ProfilePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
