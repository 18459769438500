import { Component, OnInit, forwardRef, EventEmitter, Output, Input, ViewChildren, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinInputComponent),
      multi: true
    }
  ]
})
export class PinInputComponent implements OnInit, ControlValueAccessor {
  @Output() updateUserPin = new EventEmitter<Array<string>>();
  userPin: Array<string> = new Array<string>(4);  // Initialize with empty strings or default values  
  @Input() isMasked: boolean = false;

  // Use ViewChildren to get all input fields
  @ViewChildren(`input`) inputFields!: QueryList<IonInput>;

  constructor() {}

  ngOnInit() {
    this.userPin = [``, ``, ``, ``];
  }

  writeValue(value: string | number): void {
    console.log(`[PinInputComponent:writeValue]`);   
  }

  /**
   * Handle backspace key press
   * @param event 
   * @param index 
   */
  handleBackspace(event: KeyboardEvent, index: number) {
    const currentValue: string = this.userPin[index - 1];


    // If the current input is empty, move to the previous input and delete its value
    if (event.key === `Backspace` && currentValue === ``) {
      event.preventDefault();  // Prevent backspace from affecting the previous input's value
      this.deletePreviousValue(index);
    } else if (event.key === `Backspace` && currentValue !== ``) {
      // Clear the current field's value if it's not empty
      this.userPin[index - 1] = ``;
    }
  } 

  /**
   * Move to the previous input and delete its value
   * @param index 
   */
  deletePreviousValue(index: number) {
    if (index > 1) {
      const previousInput: IonInput = this.inputFields.toArray()[index - 2]; // Move to the previous field
      if (previousInput) {
        previousInput.setFocus();  // Set focus to the previous input

        // Wait for focus to take effect, then delete the value from the previous input
        setTimeout(() => {
          this.userPin[index - 2] = ``;  // Clear the previous field's value
          const nativeElement: Promise<HTMLInputElement> = previousInput.getInputElement();
          nativeElement.then(inputEl => {
            inputEl.setSelectionRange(1, 1);  // Place the cursor at the end of the input
          });
        }, 0);
      }
    }
  }

  /**
   * Handle input value changes
   * @param index 
   * @param $event 
   */
  getValue(index: number, $event: any) {
    this.userPin[index - 1] = $event;

    // Automatically move to the next input if value is entered
    if ($event !== `` && index < 4) {
      this.setNextFocus(index);
    }

    if (this.userPin.join(``).length === 4) {
      this.updateUserPin.emit(this.userPin);
    }
  }

  /**
   * Move focus to the next input
   * @param index 
   */
  setNextFocus(index: number) {
    const nextInput: IonInput = this.inputFields.toArray()[index];
    if (nextInput) {
      nextInput.setFocus();
    }
  }

  /**
   * Clear all input fields
   */
  clearInputs() {
    this.userPin = [``, ``, ``, ``];
    this.updateUserPin.emit(this.userPin);
  }

  registerOnChange(fn: any): void {
    console.error(`Method not implemented`);
  }

  registerOnTouched(fn: any): void {
    console.error(`Method not implemented`);
  }

  setDisabledState?(isDisabled: boolean): void {
    console.error(`Method not implemented`);
  }
}
