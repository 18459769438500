import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-qrcode',
  templateUrl: './profile-qrcode.component.html',
  styleUrls: ['./profile-qrcode.component.scss'],
})
export class ProfileQRCodeComponent  implements OnInit {
  @Input() qrCodeImagePath: string = ``;  // Path for the QR code image
  @Input() bankName: string = ``;          // Bank name

  constructor() { }

  ngOnInit() {
    console.log(`ProfileQRCodeComponent`);
  }

  onShareQR(): void {
    console.log('Share QR clicked');
    // Implement sharing logic here
  }

  onDownloadQR(): void {
    console.log('Download QR clicked');
    // Implement downloading logic here
  }

}
