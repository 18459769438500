import { Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import { ButtonType } from './button-type';
import { ButtonShape } from './button-shape';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent  implements OnInit {
  @Input() displayText: string = ``;
  @Input() buttonType: ButtonType = `button` ;
  @Input() buttonShape: ButtonShape = `default`;
  @Input() buttonCss: string = ``;
  @Input() disabled: boolean = false;
  @Input() buttonColor: string = ``;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() icon: string = ``;
  @Input() width: string = `80vw`;
  @Input() height: string = ``;
  @Input() fontSize: string = ``;
  disabledBackgroundColor: string = `#F7F7F7`;
  disabledColor: string = `#DFDFDF`;
  borderColor: string = `#D9D9D9`;
  buttonBorder: string = `0.063rem solid #D9D9D9`;
  defaultColor: string = `#FFFFFF`;
  
  constructor() {}

  ngOnInit() {
    console.debug(`[ButtonComponent][ngOnInit][To be Implemented later]`)  
  }

  onClick():void {
    this.buttonClick.emit()
  }
}
