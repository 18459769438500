import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent implements OnInit {
  @Input() heading: string = ``; // Name or title to display
  @Input() description: string = ``; // Additional info like mobile number
  @Input() profileImage: string = ``; // URL for profile image
  @Input() descriptionColor: string = `black`; 
  @Input() headingColor: string = `black`;
  @Input() headingBold: boolean = false;
  @Input() descriptionIsBold: boolean = false; 
  @Input() descriptionFontSize: string = ``;
  @Input() headingFontSize: string = ``;
  @Input() firstName: string = ``;
  @Input() lastName: string = ``; 

  initials: string = ``; // Property to hold initials

  constructor() {}

  ngOnInit() {
    console.log(`User Profile Card Initialized`);
    console.log(`Profile Image:`, this.profileImage);

    // Compute initials if no profile image
    if (!this.profileImage) {
      this.initials = `${this.firstName?.charAt(0).toUpperCase()}${this.lastName?.charAt(0).toUpperCase()}`;
    }
  }
}
