import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppRoutes } from './app-routes.enum'; // Import AppRoutes enum
import { MenuController } from '@ionic/angular'; // Import MenuController

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages: { title: string; url: string; icon: string }[] = [
    { title: 'Manage Bank Account', url: `/${AppRoutes.BANK}`, icon: 'business-outline' },
    { title: 'Payment Details', url: `/${AppRoutes.HOME}`, icon: 'cash-outline' },
    { title: 'Policies', url: `/${AppRoutes.HOME}`, icon: 'documents-outline' },
    { title: 'Settings', url: `/${AppRoutes.HOME}`, icon: 'settings-outline' },
    { title: 'Help', url: `/${AppRoutes.HOME}`, icon: 'help-circle-outline' },
    { title: 'Log Out', url: `/${AppRoutes.HOME}`, icon: 'log-out-outline' },
  ];
  
  public labels: string[] = ['Version: '];
  public navigationEnd: Observable<NavigationEnd>;
  public isHomePage: boolean = false;

  constructor(public router: Router, private menuController: MenuController) {  // Inject MenuController
    this.navigationEnd = this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    );
  }

  ngOnInit(): void {
    this.navigationEnd.subscribe((event: NavigationEnd) => {
      this.isHomePage = event.urlAfterRedirects === `/${AppRoutes.HOME}`;
    });
  }

  closeMenu(): void {
    this.menuController.close(); // Use MenuController to close the menu
  }
}
