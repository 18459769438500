<form [formGroup]="paymentForm" class="form-container">
  <div class="input-row">
    <app-input inputmode="decimal" *ngIf="!disableAmount"
      class="custom-payment"    
      [inputConfig]="amountInputConfig"
      formControlName="amountControl"
      [disabled] = "disabled"
      (ionChange)="onAmountBlur()"
      [inputMode]="InputMode.NUMERIC"
    >
    </app-input>   

    <!-- New read-only input field to show the amount conditionally -->
    <app-input *ngIf="disableAmount"
      class="custom-payment"
      [inputConfig]="readOnlyAmountInputConfig"
      [value]="amount" 
      [disabled]="true"
    >
    </app-input>

  </div>
  <div class="input-row">  
    <app-input
      class="custom-reference"
      [inputConfig]="referenceInputConfig"
      formControlName="referenceControl"
      [disabled] = "disabled || disableAmount"
      [padding]="false"
    >
    </app-input>       
  </div>
  <div class="transaction"> 
<app-description [displayText]="transactionDateTime" [color]="transactionColor" [fontSize]="transactionFontSize"></app-description>
</div>

</form>
