<div class="language-selector-container">
  <button class="dropdown-toggle" (click)="toggleDropdown()">
    <img [src]="selectedLanguage?.flag" alt="{{ selectedLanguage?.label }}" class="flag" />
    <app-description [displayText]="selectedLanguage?.label || ''" [color]="languageColor"></app-description>  
    <img 
      [src]="dropdownOpen ? upArrow : downArrow" 
      alt="Dropdown Icon" 
      class="dropdown-icon" />
  </button>

  <ul *ngIf="dropdownOpen" class="dropdown-menu">
    <li *ngFor="let lang of languages" (click)="onLanguageChange(lang)">
      <img [src]="lang.flag" alt="{{ lang.label }}" class="flag" /> {{ lang.label }}
    </li>
  </ul>
</div>
