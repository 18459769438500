import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  @Input() formGroup!: FormGroup;  // Form group from the parent
  @Input() heading: string = ``;
  @Input() description: string = ``;

  @Output() formSubmit = new EventEmitter<{ email: string; password: string }>();

  showPassword: boolean = false;

  ngOnInit() {
    console.log(`Sign In Component`);
    this.clearFields();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  isFieldInvalid(field: string): boolean {
    const control = this.formGroup.get(field);
    return control ? !control.valid && control.touched : false;
  }

  getErrorMessage(field: string): string {
    if (this.formGroup.get(field)?.hasError(`required`)) {
      return `${field} is required`;
    } else if (this.formGroup.get(field)?.hasError(`email`)) {
      return `Invalid email address`;
    }
    return ``;
  }

  onForgotPassword() {
    console.log(`Forgot password clicked`);
  }

  onSubmit(buttonId: string) {
    if (this.formGroup.valid) {
      this.formSubmit.emit(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }
  /**
   * @description This function clears the input fields.
   */
  clearFields() {
    this.formGroup.reset();
  }
}
