
<app-header *ngIf="showHeader" [heading]="firstHeadingText" [description]="descriptionText"></app-header> 
<ion-row *ngIf="showSecondHeading" class="second-heading ion-text-center">
  <ion-col>
    <app-h2-heading [isBold]="true" [displayText]="secondHeadingText"></app-h2-heading>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col class="ion-text-center">
    <app-pin-input [(ngModel)]="userPin" (updateUserPin)="onUserPinChange($event)" [isMasked] = "maskPin"></app-pin-input>
    <div class="resend-code" *ngIf="showResendCode">
      <div *ngIf="timer > 0" class="resend-code-before">
        Resend Code: <span class="resend-timer">{{ timer }}s</span>
      </div>
      <div *ngIf="timer === 0" class="resend-code-after">
        <a (click)="resendCode()">Resend Code</a>
      </div>
    </div>
  </ion-col>
</ion-row>
<div class="forgot-pin-text">
  <ion-row *ngIf="showForgotPin">
  <ion-col  class="ion-text-center">
   <app-h4-heading [isBold]="true" [displayText]="forgotPinText"></app-h4-heading>
  </ion-col>
</ion-row>
<ion-row *ngIf="showNavigationLink">
  <ion-col  class="ion-text-center">
    <a (click)="onNavigate()" class="navigation-link">{{ navigationLinkText }}</a>
  </ion-col>
</ion-row>
<ion-row class="error-message">
  <ion-col>
    <app-message messageType="error" [message]="errorMessage" *ngIf="errorCondition"></app-message>
  </ion-col>
</ion-row>
</div>