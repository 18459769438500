import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
})
export class DateTimeInputComponent  implements OnInit {

  @Input() control!: FormControl;
  @Input() placeHolder: string = `Select Date`;
  @Input() presentation: `date` | `time` | `date-time` =`date`;
  @Input() preferWheel: boolean = true; 
  @Input() disabled: boolean = false;

 onDateSelected(event: any) {
    const selectedDate = new Date(event.detail.value);
    let formattedDate;
    if (this.presentation === `date`) {
      formattedDate = this.formatDate(selectedDate);
    }
    this.control.setValue(formattedDate);
  }

  formatDate(date: Date): string {
    return new Intl.DateTimeFormat(`en-GB`).format(date); // 'en-GB' locale formats date as DD/MM/YYYY
  }

  getErrorMessage() {
    if (this.control.hasError(`required`)) {
      return `This field is required`;
    }
    if (this.control.hasError(`pattern`)) {
      return `Invalid date format`;
    }
    return ``;
  }

  isFieldInvalid() {
    return !this.control.valid && this.control.touched;
  }

  constructor() { }

  ngOnInit() {
    console.log(`Date Time Input Component:`);
  }
}