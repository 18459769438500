import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentModule } from './component/component.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), // Initialize Ionic with default configuration
    AppRoutingModule, // Your routing module
    ComponentModule, // Module for shared components
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(), // Enable service worker in production mode
      registrationStrategy: 'registerWhenStable:30000' // Register after the app is stable or 30 seconds
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()), // Provide HTTP client with interceptors
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy // Use Ionic's route strategy
    }
  ],
  bootstrap: [AppComponent], // Bootstrapping AppComponent
})
export class AppModule {}
