<ion-button
  expand="block"
  [shape]="buttonShape"
  [type]="buttonType"
  [ngClass]="buttonCss"
  [ngStyle]="{
    'background-color': disabled ? '#F7F7F7' : buttonColor,
    'color': disabled ? '#DFDFDF' : (buttonColor === 'white' ? '#DFDFDF' : 'white'),
    'border': buttonColor === 'white' ? '1px solid #999999' : 'none',
    'width': width,
    'height': height,
    'font-size': fontSize
  }"
  [disabled]="disabled"
  (click)="onClick()"
>
   <!-- Display the icon if provided -->
   <ion-icon *ngIf="icon" [name]="icon" slot="start"></ion-icon>
  
  <!-- Display Text -->
  {{ displayText }}
</ion-button>
