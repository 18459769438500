<div class="modal-wrapper">
  <div class="modal-content">
        <div class="left-aligned">
          <app-h2-heading [isBold]="true" [displayText]="headingText" [fontSize]="'1.125rem'"></app-h2-heading>     
          <app-description *ngIf="showDescription" [color]="'black'" [innerHTML]="descriptionText"></app-description>
        </div>  

    <!--Payment Method-->
        <div class="payment-methods" *ngIf="showPaymentMethods">
          <div *ngFor="let method of paymentMethodItems" class="method" (click)="onMethodSelect(method)">       
              <ion-icon [name]="method.icon" class="method-icon"></ion-icon>
            <app-description [displayText]="method.paymentMethod" [color]="'black'" class="method-text"></app-description>
            <ion-icon name="chevron-forward-outline" class="method-chevron"></ion-icon>          
          </div>
        </div>     

    <!-- Input Field -->
    <div *ngIf="showInputField">
      <form [formGroup]="walloIdForm" (ngSubmit)="onSubmit()">      
        <div class="input-field">
          <app-input 
            [inputConfig]="{ label: '', placeHolder: '@wallo', inputType: 'text'}" 
            formControlName="walloId"
          >
          </app-input>        

          <!-- Error Message with reserved space -->
          <div class="error-container">
            <app-message 
              [messageType]="'error'" 
              [message]="errorMessage" 
              [ngClass]="{'visible': errorMessage, 'hidden': !errorMessage}"
            >
            </app-message>
          </div>
        </div> 

        <div class="button-verify">
          <app-button 
            *ngIf="showVerifyButton" 
            [displayText]="verifyButtonText" 
            [buttonCss]="'wp-active-btn'" 
            type="submit" 
            (buttonClick)="onSubmit()" 
            id="btn_wallo_id_submit" 
            [disabled]="!walloIdForm.valid">
          </app-button>
        </div>
      </form>
    </div>

      <div class="middle-items">
          <img
          *ngIf="showImage"
          [src]="imageSrc"
          (click)="onBiometricsAuthentication()"
          alt="Fingerprint_Icon"
          class="fingerprint-icon"
        />       
        <a
          *ngIf="showLink"     
          (click)="navigateToPinInputPage()"
          class="use-pin-link"
        >
          {{ linkText }}
        </a>    
      </div>    

    <!-- Center-aligned buttons -->
    <div *ngIf="showButtons" class="bottom-buttons">
      <!-- First Button -->
      <app-button     
        [displayText]="firstButtonText"
        [buttonCss]="'wp-active-btn'"
        [buttonColor]="firstButtonColor"
        (buttonClick)="onFirstButtonClick()"
        id="btn_first_button"
        [ngClass]="{'hidden': !showFirstButton}"
      ></app-button>      
    
      <!-- Second Button -->
      <app-button     
        [displayText]="secondButtonText"
        [buttonCss]="'wp-active-btn'"
        [buttonColor]="secondButtonColor"
        (buttonClick)="onSecondButtonClick()"        
        id="btn_second_button"
        [ngClass]="{'hidden': !showSecondButton}"
      ></app-button>      
    </div>
    
  </div>
</div>
