<div class="header">
  <div class="header-content">
    <!-- Left: Profile Picture -->
    <div class="profile-container">
      <ion-avatar class="profile-avatar">
        <img [src]="profileImageUrl" alt="Profile Pic">
      </ion-avatar>

      <div class="camera-frame" (click)="onCameraIconClick()">
        <img [src]="'assets/images/camera-icon.png'" alt="Camera Icon" class="camera-icon">
      </div>
    </div>

    <!-- Right: User Info and Button -->
    <div class="info-container">
      <!-- User Name -->
      <app-h3-heading [displayText]="userName" [isBold]="true" [color]="'#000000'"></app-h3-heading>
      <!-- Wallo ID -->
      <app-description [displayText]="'ID: ' + walloId" [color]="'#2B6EFE'" class="wallo-id" [fontSize]="'0.875rem'"></app-description>
      <!-- Edit Button -->
      <app-button 
        [displayText]="'Edit'"        
        [width]="'8.75rem'"
        [height]="'2.25rem'"
        [fontSize]="'1rem'"
        [buttonCss]="'wp-active-btn'"
        (buttonClick)="onEdit()">
      </app-button>
    </div>
  </div>
</div>
