import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MessageComponent } from './message/message.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { H2HeadingComponent } from './h2-heading/h2-heading.component';
import { H3HeadingComponent } from './h3-heading/h3-heading.component';
import { H4HeadingComponent } from './h4-heading/h4-heading.component';
import { DescriptionComponent } from './description/description.component';
import { PinComponentComponent } from './pin-component/pin-component.component';
import { BiometricsModalComponent } from './biometrics-modal/biometrics-modal.component';
import { HeaderComponent } from './header/header.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { LocaleSelectorComponent } from './locale-selector/locale-selector.component';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { HomepageHeaderComponent } from './homepage-header/homepage-header.component';
import { UserProfileCardComponent } from './user-profile-card/user-profile-card.component';
import { UserCardComponent } from './user-card/user-card.component';
import { IconContainerComponent } from './icon-container/icon-container.component';
import { IconComponent } from './icon/icon.component';
import { PayIconComponent } from './pay-icon/pay-icon.component';
import { RecentTransactionsComponent } from './recent-transactions/recent-transactions.component';
import { TransactionAmountComponent } from './transaction-amount/transaction-amount.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { PaymentInputComponent } from './payment-input/payment-input.component';
import { register } from 'swiper/element/bundle';  // Import Swiper registration
import { BankListDropdownComponent } from './bank-list-dropdown/bank-list-dropdown.component';
import { BankListViewComponent } from './bank-list-view/bank-list-view.component';
import { ManageBankPopoverComponent } from './manage-bank-popover/manage-bank-popover.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactSearchComponent } from './contact-search/contact-search.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { ProfileHeaderComponent } from './profile-header/profile-header.component';
import { ProfileQRCodeComponent } from './profile-qrcode/profile-qrcode.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { TranslateModule } from '@ngx-translate/core';

register();  // Register Swiper elements globally

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [
    MessageComponent,
    InputComponent,
    ButtonComponent,   
    PinInputComponent,
    H2HeadingComponent,
    H3HeadingComponent,
    H4HeadingComponent,
    DescriptionComponent,
    PinComponentComponent,
    BiometricsModalComponent, 
    HeaderComponent,
    SignInComponent,
    LocaleSelectorComponent,
    DateTimeInputComponent,
    HomepageHeaderComponent,
    UserProfileCardComponent,    
    UserCardComponent,
    IconComponent,
    IconContainerComponent,
    PayIconComponent,
    RecentTransactionsComponent,
    TransactionAmountComponent,
    UserPaymentComponent,
    PaymentInputComponent,
    BankListDropdownComponent,
    BankListViewComponent,
    ManageBankPopoverComponent,
    ContactListComponent,
    ContactSearchComponent,
    NotificationsListComponent,
    ProfileHeaderComponent,
    ProfileQRCodeComponent,
    ProfileFormComponent,
  ],
  exports: [
    MessageComponent,
    InputComponent,
    ButtonComponent,    
    PinInputComponent,
    H2HeadingComponent,
    H3HeadingComponent,
    H4HeadingComponent,
    DescriptionComponent,
    PinComponentComponent,
    BiometricsModalComponent,    
    HeaderComponent,
    SignInComponent,
    LocaleSelectorComponent,
    DateTimeInputComponent,
    HomepageHeaderComponent,
    UserProfileCardComponent,  
    UserCardComponent,  
    IconComponent,
    IconContainerComponent,
    PayIconComponent,
    RecentTransactionsComponent,
    TransactionAmountComponent,
    UserPaymentComponent,
    PaymentInputComponent,
    BankListDropdownComponent,
    BankListViewComponent,
    ManageBankPopoverComponent,
    ContactListComponent,
    ContactSearchComponent,
    NotificationsListComponent,
    ProfileHeaderComponent,
    ProfileQRCodeComponent,
    ProfileFormComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentModule {}
