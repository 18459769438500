import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent  implements OnInit {

  @Input() userName: string = ``;
  @Input() walloId: string = ``;
  @Input() profileImageUrl: string = ``;
  @Input() editButtonText: string = `Edit`; 
  cameraIconUrl: string = `assets/images/camera-icon.png`;
  userNameColor: string = `#000000`;
  walloIdColor: string = `#2B6EFE`;
  walloIdFontSize: string = `0.875rem`; 
  editButtonWidth: string = `8.75rem`;
  editButtonHeight: string = `2.25rem`;
  editButtonFontSize: string = `1rem`;
  editButtonCssClass: string = `wp-active-btn`;

   // Emit event to parent when the Edit button is clicked
   @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
    console.log(`ProfileHeaderComponent`);
  }
  onEdit(): void {   
    console.log(`Edit button clicked`);
    this.editClicked.emit(); // Emit the event to the parent component
  }

  onCameraIconClick(): void {
    // Logic for handling camera icon click will be implemented later
    console.log(`Camera icon clicked`);
  }

}
