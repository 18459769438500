import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-icon-container',
  templateUrl: './icon-container.component.html',
  styleUrls: ['./icon-container.component.scss'],
})
export class IconContainerComponent implements OnInit, OnDestroy {
  /* TODO: Remove the inline initialization and import from the model */
  @Input() icons: { iconName: string; bgColor: string; text: string; route: string }[] = [];
  @Input() containerBgColor: string = ``;
  activeIcon: string = ``;
  private routerSubscription: Subscription | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.updateActiveIcon(this.router.url);

    this.routerSubscription = this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationEnd) {
        this.updateActiveIcon(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Update the active icon based on the current route
   * @param {string} url - The current route URL
   */
  private updateActiveIcon(url: string): void {
    // Explicitly define the type of currentIcon as the icon object or undefined
    const currentIcon: { iconName: string; bgColor: string; text: string; route: string } | undefined = 
      this.icons.find((icon) => url.includes(icon.route));

    if (currentIcon) {
      this.activeIcon = currentIcon.text;
    } else {
      this.activeIcon = this.icons[0]?.text || '';
    }
  }

  /**
   * Handle icon clicks to navigate to the appropriate page
   * @param {string} iconText
   */
  onIconClick(iconText: string): void {
    this.activeIcon = iconText;
    const icon: { iconName: string; bgColor: string; text: string; route: string } | undefined = 
      this.icons.find((icon) => icon.text === iconText);
    if (icon) {
      this.router.navigate([icon.route]);
    }
  }

  /**
   * Check if the given icon is active
   * @param {string} iconText
   * @returns {boolean}
   */
  isActive(iconText: string): boolean {
    return this.activeIcon === iconText;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
