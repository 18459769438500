<ion-list class="popover-list">
  <ion-item
    *ngFor="let action of actions"
    button
    [disabled]="action.isDisabled()"
    (click)="performAction(action.action)"
    [detail]="false"
    class="popover-item"
  >
    {{ action.label }}
  </ion-item>
</ion-list>
