<div class="user-profile">
  <!-- FAB with image or initials -->
  <ion-fab vertical="center" horizontal="center" slot="fixed" class="fab-container">
    <ion-fab-button class="custom-fab-button">
      <!-- Display Profile Image if available -->
      <ng-container *ngIf="profileImage; else initialsFab">
        <img [src]="profileImage" class="fab-image" alt="Profile" />
      </ng-container>

      <!-- Display Initials if no profile image -->
      <ng-template #initialsFab>
        <div class="fab-initials">{{ initials }}</div>
      </ng-template>
    </ion-fab-button>
  </ion-fab>

  <!-- Heading -->
  <div class="user-name">
    <app-h3-heading [displayText]="heading" [isBold]="isUserNameBold"></app-h3-heading>
  </div>
</div>
