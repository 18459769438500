<form [formGroup]="paymentForm" class="form-container">
  <div class="input-row">
    <app-input inputmode="decimal" *ngIf="!disableAmount"
      class="custom-payment"    
      [inputConfig]="{ 
        label: '', 
        placeHolder: '$0.00', 
        inputType: 'text', 
        backgroundColor: disabled ? 'transparent' : '#f8f8f8', 
        borderStyle: 'none',
        fontSize: '1.5rem', 
        fontWeight: '600',
        textAlign: 'center'
      }"
      formControlName="amountControl"
      [disabled] = "disabled"
      (ionChange)="onAmountBlur()"
      [inputMode]="InputMode.NUMERIC"
    >
    </app-input>   

    <!-- New read-only input field to show the amount conditionally -->
    <app-input *ngIf="disableAmount"
      class="custom-payment"
      [inputConfig]="{ 
        label: '', 
        placeHolder: '', 
        inputType: 'text', 
        backgroundColor: 'transparent', 
        borderStyle: 'none',
        fontSize: '1.5rem', 
        fontWeight: '600',
        textAlign: 'center'
      }"
      [value]="amount" 
      [disabled]="true"
    >
    </app-input>

  </div>
  <div class="input-row">  
    <app-input
      class="custom-reference"
      [inputConfig]="{
        label: '',
        placeHolder: 'Reference: (Optional)',
        inputType: 'text',
        borderStyle: 'none',
        backgroundColor: 'transparent',
        maxLength: 12,
        textAlign: 'center',        
      }"
      formControlName="referenceControl"
      [disabled] = "disabled || disableAmount"
      [padding]="false"
    >
    </app-input>       
  </div>
  <div class="transaction"> 
<app-description [displayText]="transactionDateTime" [color]="'#999999'" [fontSize]="'0.875rem'"></app-description>
</div>

</form>
