import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';


 /* TODO: Remove this initialization and import from the model */
type PaymentData = {
  amount: string;
  reference: string;
};

type InputType = `email` |  `password` | `text` | `tel`;

enum InputMode {  
  NUMERIC = 'numeric',   
  }
  
@Component({
  selector: `app-payment-input`,
  templateUrl: `./payment-input.component.html`,
  styleUrls: [`./payment-input.component.scss`],
})
export class PaymentInputComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() amount: string = ``;
  @Input() reference: string = ``;
  @Input() transactionDateTime: string = ``;
  @Input() disableAmount: boolean = false;

  @Output() paymentData: EventEmitter<PaymentData> = new EventEmitter<PaymentData>();
  transactionColor:string = `#000000`;
  transactionFontSize:string = `0.875rem`;
 
  // Configuration objects for inputs
  amountInputConfig = {
    label: '',
    placeHolder: '$0.00',
    inputType: 'text' as InputType, 
    backgroundColor: this.disabled ? 'transparent' : '#f8f8f8',
    borderStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '600',
    textAlign: 'center',
  };


  readOnlyAmountInputConfig = {
    label: '',
    placeHolder: '',
    inputType: 'text' as InputType, 
    backgroundColor: 'transparent',
    borderStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '600',
    textAlign: 'center',
  };

  referenceInputConfig = {
    label: '',
    placeHolder: 'Reference: (Optional)',
    inputType: 'text' as InputType,
    borderStyle: 'none',
    backgroundColor: 'transparent',
    maxLength: 12,
    textAlign: 'center',
  };

  paymentForm: FormGroup;
  public InputMode = InputMode;

  constructor() {
    this.paymentForm = new FormGroup({
      amountControl: new FormControl(this.amount),
      referenceControl: new FormControl(this.reference),
    });

    // Subscribe to value changes on the amount control to handle formatting and emission
    this.paymentForm.get(`amountControl`)?.valueChanges.subscribe((value: string) => {
      this.formatAndEmitAmount(value);
    });

    this.paymentForm.get(`referenceControl`)?.valueChanges.subscribe(() => {
      this.emitData();
    });
  }

  /**
   * @desc Initializes the component, disabling the form controls if in read-only mode.
   */
  ngOnInit(): void {
    console.log(`Payment Input Component`);
    console.log(`Amount:`, this.amount);
    this.updateAmountInputConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.updateAmountInputConfig();
    }
  }
 
  private updateAmountInputConfig(): void {
    this.amountInputConfig = {
      ...this.amountInputConfig,
      backgroundColor: this.disabled ? `transparent` : `#f8f8f8`,
    };
  }

  /**
   * @desc Formats the input value by adding a dollar sign if not present, updates the form control, and emits the data.
   * @param value - The input value from the amount control.
   */
  private formatAndEmitAmount(value: string): void {
    if (!value.startsWith(`$`)) {
      value = `$` + value;
    }

    // Update the input field to show the $ prefix
    const formattedValue: string = value;
    this.paymentForm.get(`amountControl`)?.setValue(formattedValue, { emitEvent: false });

    // Emit the formatted value with two decimal places
    this.emitData();
  }

  /**
   * @desc Formats the value for emission by removing the dollar sign and formatting it to two decimal places.
   * @param value - The string value to be formatted.
   * @returns A formatted string with two decimal places and a dollar sign.
   */
  private formatValueForEmission(value: string): string {
    // Remove the $ for numeric parsing
    const numericValue: number = parseFloat(value.replace(/[^0-9.-]+/g, ``));

    // Return the formatted value with two decimal places for emission
    return isNaN(numericValue) ? value : `$${numericValue.toFixed(2)}`;
  }

  /**
   * @desc Handles the blur event on the amount input, formatting the value to two decimal places.
   */
  onAmountBlur(): void {
    const amountControl: FormControl<string> | null = this.paymentForm.get(`amountControl`) as FormControl<string>;
    if (amountControl) {
      const value: string = amountControl.value.replace(`$`, ``);
      const numericValue: number = parseFloat(value);

      if (!isNaN(numericValue)) {
        // Format the value with two decimal places on blur
        const formattedValue: string = `$${numericValue.toFixed(2)}`;
        amountControl.setValue(formattedValue, { emitEvent: false });
      }
    }
  }

  /**
   * @desc Emits the current values of the amount and reference controls.
   */
  emitData(): void {
    const amountControl: FormControl<string> | null = this.paymentForm.get(`amountControl`) as FormControl<string>;
    const referenceValue: string = this.paymentForm.get(`referenceControl`)?.value || ``;

    if (amountControl) {
      const formattedAmount: string = this.formatValueForEmission(amountControl.value);
      console.log(`Emitting: ${formattedAmount}, Reference: ${referenceValue}`);
      this.paymentData.emit({
        amount: formattedAmount,
        reference: referenceValue,
      });
    }
  }
}
