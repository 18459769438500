import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: `app-user-payment`,
  templateUrl: `./user-payment.component.html`,
  styleUrls: [`./user-payment.component.scss`],
})
export class UserPaymentComponent implements OnInit {
  @Input() heading: string = ``;  
  @Input() profileImage: string = ``;
  initials: string = ``;  // Property to hold initials
  isUserNameBold: boolean = true;

  constructor() { }

  ngOnInit() {
    console.log(`User Payment`);

    console.log(`Profile Image:`,this.profileImage);

    // Compute initials if no profile image
    if (!this.profileImage) {
      const names = this.heading.split(` `);
      this.initials = names.map(name => name.charAt(0).toUpperCase()).join(``);  // Get initials from heading
   }
  }
}
